import { FileEarmarkPlus } from "react-bootstrap-icons";

function NoTemplate() {
  return (
    <div className="text-center">
      <FileEarmarkPlus size={30} className="mx-auto" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">No Templates</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new template.
      </p>
    </div>
  );
}

export default NoTemplate;
