import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  [otherProps: string]: any;
}

function PropertyLabel({ children, ...otherProps }: Props) {
  return (
    <div className="mb-0.5 font-semibold" {...otherProps}>
      {children}
    </div>
  );
}

export default PropertyLabel;
