import { PRICING } from "./constant";
import { Payment } from "../type";

export interface IPlan {
  POPULAR: boolean;
  TITLE: string;
  PRICE_MONTHLY: number;
  PRICE_YEARLY: number;
  RENDERING_LIMIT: number;
  TEMPLATE_LIMIT: number;
  TIP: string;
  MONTHLY_PRODUCT_ID?: string;
  YEARLY_PRODUCT_ID?: string;
}

const PLAN: Record<string, IPlan> = {
  FREE: {
    POPULAR: false,
    TITLE: "FREE",
    PRICE_MONTHLY: 0,
    PRICE_YEARLY: 0,
    RENDERING_LIMIT: 30,
    TEMPLATE_LIMIT: 3,
    TIP: "Start from a free plan, try things out and upgrade any time!",
  },
  BASIC: {
    POPULAR: true,
    TITLE: "BASIC",
    PRICE_MONTHLY: 29,
    PRICE_YEARLY: 22.5,
    RENDERING_LIMIT: 1500,
    TEMPLATE_LIMIT: 20,
    TIP: "This plan is suitable for small teams and hobbyists!",
    MONTHLY_PRODUCT_ID: PRICING.BASIC.MONTHLY_PRODUCT_ID,
    YEARLY_PRODUCT_ID: PRICING.BASIC.YEARLY_PRODUCT_ID,
  },
  ADVANCED: {
    POPULAR: false,
    TITLE: "ADVANCED",
    PRICE_MONTHLY: 69,
    PRICE_YEARLY: 55,
    RENDERING_LIMIT: 5000,
    TEMPLATE_LIMIT: 200,
    TIP: "Automate the entire process for everything you do!",
    MONTHLY_PRODUCT_ID: PRICING.ADVANCED.MONTHLY_PRODUCT_ID,
    YEARLY_PRODUCT_ID: PRICING.ADVANCED.YEARLY_PRODUCT_ID,
  },
  PREMIUM: {
    POPULAR: false,
    TITLE: "PREMIUM",
    PRICE_MONTHLY: 169,
    PRICE_YEARLY: 135,
    RENDERING_LIMIT: 20000,
    TEMPLATE_LIMIT: Infinity,
    TIP: "An premium plan for those who want to make something big!",
    MONTHLY_PRODUCT_ID: PRICING.PREMIUM.MONTHLY_PRODUCT_ID,
    YEARLY_PRODUCT_ID: PRICING.PREMIUM.YEARLY_PRODUCT_ID,
  },
};

const getPlanTextFromPayment = (payment?: Payment): string => {
  return getPlanFromPayment(payment).TITLE;
};

const getPlanById = (planId: string): IPlan => {
  if (
    planId === PRICING.BASIC.MONTHLY_PRODUCT_ID ||
    planId === PRICING.BASIC.YEARLY_PRODUCT_ID
  ) {
    return PLAN.BASIC;
  }

  if (
    planId === PRICING.ADVANCED.MONTHLY_PRODUCT_ID ||
    planId === PRICING.ADVANCED.YEARLY_PRODUCT_ID
  ) {
    return PLAN.ADVANCED;
  }

  if (
    planId === PRICING.PREMIUM.MONTHLY_PRODUCT_ID ||
    planId === PRICING.PREMIUM.YEARLY_PRODUCT_ID
  ) {
    return PLAN.PREMIUM;
  }

  return PLAN.FREE;
};

const getPlanFromPayment = (payment?: Payment): IPlan => {
  if (!payment) {
    return PLAN.FREE;
  }

  const {
    status,
    subscription_plan_id: planId,
    cancellation_effective_date: cancellationEffectiveDate,
  } = payment;

  if (status === "deleted") {
    const now = new Date();
    const expiredDate = new Date(cancellationEffectiveDate);

    // even though the plan has been cancelled(deleted),
    // the user still has access to the plan until it"s expired
    if (now <= expiredDate) {
      return getPlanById(planId);
    }

    return PLAN.FREE;
  }

  return getPlanById(planId);
};

export { PLAN, getPlanTextFromPayment, getPlanFromPayment };
