import { INTERNAL_SERVER_URL } from "@constants/api";
import { authInstance } from "@utils/firebase";

async function createUser() {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(`${INTERNAL_SERVER_URL}/user/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
  const result = await response.json();
  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

async function getUser() {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(`${INTERNAL_SERVER_URL}/user/get`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
  const result = await response.json();
  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

export { createUser, getUser };
