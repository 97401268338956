import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Color from "./shared/Color";
import NumberInput from "./shared/NumberInput";
import { DEFAULT_STROKE_WIDTH } from "@utils/constant";

const DEFAULT_STROKE = "#FFFFFF";

function Stroke() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [stroke, setStroke] = useState(
    selectedObject?.stroke || DEFAULT_STROKE
  );
  const [strokeWidth, setStrokeWidth] = useState(
    selectedObject?.strokeWidth || DEFAULT_STROKE_WIDTH
  );

  // triggered by clicking layers
  useEffect(() => {
    setStroke(selectedObject?.stroke || DEFAULT_STROKE);
    setStrokeWidth(selectedObject?.strokeWidth || DEFAULT_STROKE_WIDTH);
  }, [selectedObject]);

  function onColorChange(newColor) {
    selectedObject?.set({ stroke: newColor });
    setStroke(newColor);
    canvas?.renderAll();
  }

  function onStrokeWidthChange(event: React.ChangeEvent<HTMLInputElement>) {
    let newStrokeWidth = parseInt(event.target.value, 10);
    if (isNaN(newStrokeWidth)) {
      newStrokeWidth = 0;
    }
    selectedObject?.set({ strokeWidth: newStrokeWidth });
    setStrokeWidth(newStrokeWidth);
    canvas?.renderAll();
  }

  return (
    <div>
      <div className="mb-0.5 flex space-x-2">
        <div className="w-8 text-center text-sm font-semibold">&nbsp;</div>
        <div className="flex-1 text-sm font-semibold">Width</div>
      </div>
      <div className="flex items-center justify-center space-x-2">
        <Color color={stroke} onChange={onColorChange} />
        <NumberInput
          onChange={onStrokeWidthChange}
          value={strokeWidth}
          min={0}
          step={1}
          className="h-8 w-full rounded"
        />
      </div>
    </div>
  );
}

export default Stroke;
