import { useContext, useEffect, useState } from "react";
import FontPicker from "react-fontpicker-ts";
import { fabric } from "@utils/fabric";
import { DEFAULT_FONT_OBJECT } from "@utils/constant";
import { AppContext } from "../../context/AppContext";
import installedFonts from "@utils/font/server-fonts.info.json";

function FontFamily({ onChange = () => {} }: { onChange: () => void }) {
  const { selectedObject } = useContext(AppContext);
  const [fontFamily, setFontFamily] = useState(
    (selectedObject as fabric.Textbox)?.get("fontFamily")
  );

  // triggered by clicking layers
  useEffect(() => {
    setFontFamily((selectedObject as fabric.Textbox)?.get("fontFamily"));
  }, [selectedObject]);

  function onFontChange(newFontFamily: string) {
    // Notice
    // FontPicker would trigger this for the first time with the default font
    // So we need to check if the font is actually changed before updating the state
    if (fontFamily === newFontFamily) {
      return;
    }

    setFontFamily(newFontFamily);
    (selectedObject as fabric.Textbox)?.set({ fontFamily: newFontFamily });

    onChange();
  }

  return (
    <div>
      <FontPicker
        autoLoad
        defaultValue={fontFamily}
        googleFonts={installedFonts}
        localFonts={[DEFAULT_FONT_OBJECT]}
        value={(selectedFontFamily) => {
          onFontChange(selectedFontFamily);
        }}
      />
    </div>
  );
}

export default FontFamily;
