import { Basic } from "unsplash-js/dist/methods/photos/types";
import { UNSPLASH_APP_NAME } from "@utils/constant";

function UnsplashImage({
  data,
  selected = false,
  onImageClick = (image: Basic) => {},
}: {
  data: Basic;
  selected: boolean;
  onImageClick: (image: Basic) => void;
}) {
  const suffix = `?utm_source=${UNSPLASH_APP_NAME}&utm_medium=referral`;
  const siteBackLink = `https://unsplash.com/${suffix}`;
  const profileBackLink = `${data.user.links.html}${suffix}`;

  return (
    <div
      className={`relative h-48 w-1/2 overflow-hidden rounded-sm border-4 ${
        selected ? "border-blue-500" : "border-white"
      }`}
    >
      <img
        src={data.urls.regular}
        alt={data.alt_description || "unsplash image"}
        className="w-full cursor-pointer object-cover"
        onClick={() => {
          onImageClick(data);
        }}
      ></img>
      <p className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 p-2 text-xs text-white">
        Photo by{" "}
        <a
          className="font-bold hover:underline"
          href={profileBackLink}
          target="_blank"
          rel="noreferrer"
        >
          {data.user.name}{" "}
        </a>
        on{" "}
        <a
          className="font-bold hover:underline"
          href={siteBackLink}
          target="_blank"
          rel="noreferrer"
        >
          Unsplash
        </a>
      </p>
    </div>
  );
}

export default UnsplashImage;
