/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition, Switch } from "@headlessui/react";
import { toast } from "react-toastify";
import {
  DEFAULT_TEMPLATE_TITLE,
  DEFAULT_TEMPLATE_WIDTH,
  DEFAULT_TEMPLATE_HEIGHT,
  MAX_TEMPLATE_WIDTH,
  MAX_TEMPLATE_HEIGHT,
} from "@utils/constant";
import CopyButton from "../shared/CopyButton";

type TemplateInfo = {
  id?: string;
  title: string;
  width: number;
  height: number;
  public: boolean;
};

function TemplateSettingsModal({
  header,
  templateInfo,
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
}: {
  header: string;
  templateInfo?: TemplateInfo;
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (templateInfo: TemplateInfo) => void;
}) {
  const cancelButtonRef = useRef(null);
  const templateId = templateInfo?.id || "";
  const [title, setTitle] = useState(
    templateInfo?.title || DEFAULT_TEMPLATE_TITLE
  );
  const [width, setWidth] = useState(
    templateInfo?.width || DEFAULT_TEMPLATE_WIDTH
  );
  const [height, setHeight] = useState(
    templateInfo?.height || DEFAULT_TEMPLATE_HEIGHT
  );
  const [isPublic, setIsPublic] = useState(templateInfo?.public || false);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div>
                  <div className="text-center sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="mb-8 text-2xl font-bold leading-6 text-gray-900"
                    >
                      {header}
                    </Dialog.Title>
                    <div>
                      {templateId !== "" && (
                        <div className="mt-2">
                          <div className="mb-4">
                            <label className="text-md mb-2 block font-bold text-gray-700">
                              Template id
                            </label>
                            <div className="mb-2 flex items-center rounded bg-blue-100 p-2">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="mr-1 h-4 w-4 text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                ></path>
                              </svg>
                              <div className="text-sm">
                                You can check
                                <a
                                  href="https://docs.copicake.com/#/api/v1/image/create"
                                  className="mx-1 text-blue-500"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  our docs
                                </a>
                                to learn how to use this.
                              </div>
                            </div>
                            <div className="flex space-x-2">
                              <input
                                className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500"
                                type="text"
                                placeholder={DEFAULT_TEMPLATE_TITLE}
                                disabled
                                value={templateId}
                              />
                              <CopyButton
                                text={templateId}
                                onCopy={() => {
                                  toast.success("Copied your Template id");
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="mt-2">
                        <div className="mb-4">
                          <label className="text-md mb-2 block font-bold text-gray-700">
                            Title
                          </label>
                          <input
                            className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                            type="text"
                            placeholder={DEFAULT_TEMPLATE_TITLE}
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="mb-4 flex justify-between">
                          <div>
                            <label className="text-md mb-2 block font-bold text-gray-700">
                              Width (px)
                            </label>
                            <input
                              className="focus:shadow-outline mb-2 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                              type="number"
                              placeholder="width"
                              value={width}
                              onChange={(e) =>
                                setWidth(parseInt(e.target.value))
                              }
                            />
                            <div className="text-xs font-bold text-gray-400">
                              Max Width: {MAX_TEMPLATE_WIDTH} px
                            </div>
                          </div>
                          <div>
                            <label className="text-md mb-2 block font-bold text-gray-700">
                              Height (px)
                            </label>
                            <input
                              className="focus:shadow-outline mb-2 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                              type="number"
                              placeholder="height"
                              value={height}
                              onChange={(e) =>
                                setHeight(parseInt(e.target.value))
                              }
                            />
                            <div className="text-xs font-bold text-gray-400">
                              Max Height: {MAX_TEMPLATE_HEIGHT} px
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2">
                        <div className="mb-4">
                          <label className="text-md mb-2 block font-bold text-gray-700">
                            Public
                          </label>
                          <Switch
                            checked={isPublic}
                            onChange={setIsPublic}
                            className={`relative mb-2 inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 ${
                              isPublic ? "bg-indigo-600" : "bg-gray-200"
                            }`}
                          >
                            <span className="sr-only">isPublic</span>
                            <span
                              aria-hidden="true"
                              className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                                isPublic ? "translate-x-5" : "translate-x-0"
                              }`}
                            />
                          </Switch>
                          <div className="text-xs font-bold text-gray-400">
                            If you have marked your template as public, other
                            users have the ability to duplicate your design and
                            utilize it for their own purposes.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    onSubmit({
                      width,
                      height,
                      title,
                      public: isPublic,
                    });
                  }}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    onCancel();
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default TemplateSettingsModal;
