import { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import ReactTooltip from "react-tooltip";
import { BiArrowToLeft, BiArrowToRight } from "react-icons/bi";
import { AppContext } from "../context/AppContext";
import LayerItem from "./LayerItem";
import LayersEmpty from "./LayersEmpty";
import { WORKSPACE_ID } from "@utils/constant";

const ICON_SIZE = 16;

function Layers({
  isLayerOpened = true,
  onLayerToggle = () => {},
}: {
  isLayerOpened: boolean;
  onLayerToggle: () => void;
}) {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [objects, setObjects] = useState<fabric.Object[]>([]);

  useEffect(() => {
    function updateObjects() {
      let objects = canvas?.getObjects() || [];
      objects = objects.filter((object) => (object as any).id !== WORKSPACE_ID);
      objects.reverse();
      setObjects(objects);
      ReactTooltip.rebuild();
    }

    if (canvas && coreHandler) {
      coreHandler?.eventHandler.on("object:added", () => {
        updateObjects();
      });

      coreHandler?.eventHandler.on("object:removed", () => {
        updateObjects();
      });

      coreHandler?.eventHandler.on("canvas:reorder", () => {
        updateObjects();
      });

      coreHandler?.eventHandler.on("design:loaded", () => {
        updateObjects();
      });
    }
  }, [canvas, coreHandler]);

  return (
    <div className="relative h-full divide-y overflow-auto border-r border-b">
      <div className={`flex items-center space-x-2 bg-gray-100 p-2`}>
        <div className="flex items-center justify-center">
          <button
            className="rounded p-2 hover:bg-gray-300"
            onClick={() => {
              onLayerToggle();
            }}
          >
            {isLayerOpened && <BiArrowToLeft size={ICON_SIZE} />}
            {!isLayerOpened && <BiArrowToRight size={ICON_SIZE} />}
          </button>
          {isLayerOpened && <p className="ml-1 text-sm font-bold">Layers</p>}
        </div>
      </div>
      {objects.length === 0 && isLayerOpened && (
        <div className="flex h-[calc(100%-48px)] items-center justify-center">
          <LayersEmpty />
        </div>
      )}
      {objects.length !== 0 &&
        objects.map(function (object, index) {
          return (
            <LayerItem
              key={index}
              object={object}
              selected={selectedObject === object}
              isLayerOpened={isLayerOpened}
            />
          );
        })}
    </div>
  );
}

export default Layers;
