import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";

function FontSize() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [fontSize, setFontSize] = useState(
    (selectedObject as fabric.Textbox)?.get("fontSize")
  );

  // triggered by clicking layers
  useEffect(() => {
    setFontSize((selectedObject as fabric.Textbox)?.get("fontSize"));
  }, [selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = parseInt(event.target.value, 10);
    setFontSize(value);
    (selectedObject as fabric.Textbox)?.set({ fontSize: value });
    canvas?.renderAll();
  }

  return (
    <div>
      <input
        type="number"
        onChange={onChange}
        value={fontSize}
        min={1}
        className="h-8 w-full rounded"
      />
    </div>
  );
}

export default FontSize;
