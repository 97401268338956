import { fabric } from "@utils/fabric";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Color from "./shared/Color";
import NumberInput from "./shared/NumberInput";

function createDefaultShadow() {
  return new fabric.Shadow({
    color: "#ffffff",
    blur: 5,
    offsetX: 0,
    offsetY: 0,
  });
}

function Shadow() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [shadow, setShadow] = useState(
    (selectedObject?.get("shadow") as fabric.Shadow) || createDefaultShadow()
  );

  // triggered by clicking layers
  useEffect(() => {
    const shadow =
      (selectedObject?.get("shadow") as fabric.Shadow) || createDefaultShadow();
    const newShadow = new fabric.Shadow(shadow.toObject());
    setShadow(newShadow);
  }, [selectedObject]);

  function onColorChange(newColor) {
    const newShadow = new fabric.Shadow(shadow.toObject());
    newShadow.color = newColor;
    selectedObject?.set({ shadow: newShadow });
    setShadow(newShadow);
    canvas?.renderAll();
  }

  function onBlurChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newBlur = parseInt(event.target.value, 10);
    const newShadow = new fabric.Shadow(shadow.toObject());
    newShadow.blur = newBlur;
    selectedObject?.set({ shadow: newShadow });
    setShadow(newShadow);
    canvas?.renderAll();
  }

  function onOffsetXChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newOffsetX = parseInt(event.target.value, 10);
    const newShadow = new fabric.Shadow(shadow.toObject());
    newShadow.offsetX = newOffsetX;
    selectedObject?.set({ shadow: newShadow });
    setShadow(newShadow);
    canvas?.renderAll();
  }

  function onOffsetYChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newOffsetY = parseInt(event.target.value, 10);
    const newShadow = new fabric.Shadow(shadow.toObject());
    newShadow.offsetY = newOffsetY;
    selectedObject?.set({ shadow: newShadow });
    setShadow(newShadow);
    canvas?.renderAll();
  }

  return (
    <div>
      <div className="mb-0.5 flex space-x-2">
        <div className="w-8 text-center text-sm font-semibold">&nbsp;</div>
        <div className="flex-1 text-sm font-semibold">Blur</div>
        <div className="flex-1 text-sm font-semibold">X</div>
        <div className="flex-1 text-sm font-semibold">Y</div>
      </div>

      <div className="flex items-center justify-center space-x-2">
        <Color color={shadow.color!} onChange={onColorChange} />
        <NumberInput
          onChange={onBlurChange}
          value={shadow.blur}
          min={0}
          step={1}
          className="h-8 w-full rounded"
        />
        <NumberInput
          onChange={onOffsetXChange}
          value={shadow.offsetX}
          min={0}
          step={1}
          className="h-8 w-full rounded"
        />
        <NumberInput
          onChange={onOffsetYChange}
          value={shadow.offsetY}
          min={0}
          step={1}
          className="h-8 w-full rounded"
        />
      </div>
    </div>
  );
}

export default Shadow;
