import { fabric } from "@utils/fabric";
import { WORKSPACE_ID } from "@utils/constant";

export function extendFabricProperties() {
  // Object
  const originalSet = fabric.Object.prototype.set;
  fabric.Object.prototype.set = function (key, value) {
    const result = originalSet.call(this, key, value);
    this.canvas?.fire("property:changed");
    return result;
  };

  // Canvas
  const _toObject = fabric.Canvas.prototype.toObject;
  const _toJSON = fabric.Canvas.prototype.toJSON;

  fabric.Canvas.prototype.toObject = function () {
    const result = _toObject.call(this);
    delete result.clipPath;
    result.objects = result.objects.filter((eachObject) => {
      return eachObject.id !== WORKSPACE_ID;
    });
    return result;
  };

  fabric.Canvas.prototype.toJSON = function () {
    const result = _toJSON.call(this);
    delete result.clipPath;
    result.objects = result.objects.filter((eachObject) => {
      return eachObject.id !== WORKSPACE_ID;
    });
    return result;
  };

  fabric.Canvas.prototype._toJSON = function () {
    const result = _toJSON.call(this);
    return result;
  };
}
