import { ReactNode } from "react";

interface Props {
  children?: ReactNode;
  [otherProps: string]: any;
}

function PropertyHint({ children, ...otherProps }: Props) {
  return (
    <div
      className="my-0.5 ml-2 text-xs font-bold text-gray-400"
      {...otherProps}
    >
      {children}
    </div>
  );
}

export default PropertyHint;
