import { fabric } from "@utils/fabric";
import { DEFAULT_STROKE_WIDTH } from "./constant";
export type ObjectType = "circle" | "rect" | "triangle" | "text" | "qrcode";

// http://fabricjs.com/fabric-gotchas
// Objects have a transparent stroke of width 1 by default
const strokeWidth = DEFAULT_STROKE_WIDTH;

export function createDefaultObject(type: ObjectType): fabric.Object {
  let object: fabric.Object;

  if (type === "circle") {
    object = new fabric.Circle({
      fill: "#00FF00",
      radius: 50,
      strokeWidth,
    });
  } else if (type === "triangle") {
    object = new fabric.Triangle({
      fill: "#0000FF",
      width: 50,
      height: 50,
      strokeWidth,
    });
  } else if (type === "rect") {
    object = new fabric.Rect({
      fill: "#FF0000",
      width: 50,
      height: 50,
      strokeWidth,
    });
  } else if (type === "text") {
    object = new fabric.Textbox("Hello World", {
      fill: "#000000",
      fontSize: 20,
      width: 100,
      textAlign: "left",
      strokeWidth,
    });
    object.setControlsVisibility({
      mt: false,
      mb: false,
    });
  } else if (type === "qrcode") {
    object = new fabric.Qrcode({
      content: "https://copicake.com",
    });
  } else {
    throw new Error("not supported");
  }

  return object;
}

export function debounce(fn: Function, delay = 200) {
  let timer;

  return function () {
    const args = arguments;

    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(null, args);
    }, delay);
  };
}

export function resetTextboxMaxLines(textbox: fabric.Textbox) {
  const text = textbox.text;
  if (text) {
    const result = textbox._splitTextIntoLines(text);
    const maxLines = result.graphemeLines.length;
    textbox.set("maxLines", maxLines);
  }
}
