import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { fabric } from "@utils/fabric";
import { AppContext } from "../context/AppContext";
import { IEventWithSelected } from "@utils/type";
import CoreHandler from "../handlers/CoreHandler";
import Loader from "./shared/Loader";
import { Template } from "../type";
import { CANVAS_ID, DEFAULT_WORKSPACE_BACKGROUND_COLOR } from "@utils/constant";

interface IProps {
  template: Template;
}

function Canvas({ template }: IProps) {
  const {
    canvas: savedCanvas,
    setCanvas,
    setSelectedObject,
    setCoreHandler,
  } = useContext(AppContext);

  const [canvasHidden, setCanvasHidden] = useState(true);
  const location = useLocation();

  useEffect(() => {
    function onSelectionCreated(event: IEventWithSelected) {
      const selected = event.selected;
      setSelectedObject(selected[0]);
    }

    function onSelectionUpdated(event: IEventWithSelected) {
      const selected = event.selected;
      setSelectedObject(selected[0]);
    }

    function onSelectionCleared(event: IEventWithSelected) {
      setSelectedObject(null);
    }

    function onDesignLoaded() {
      setCanvasHidden(false);
    }

    const canvas = new fabric.Canvas(CANVAS_ID, {
      backgroundColor: DEFAULT_WORKSPACE_BACKGROUND_COLOR,
      controlsAboveOverlay: true,
    });

    const coreHandler = new CoreHandler(canvas);
    (window as any).coreHandler = coreHandler;

    coreHandler.eventHandler.on("selection:created", onSelectionCreated);
    coreHandler.eventHandler.on("selection:updated", onSelectionUpdated);
    coreHandler.eventHandler.on("selection:cleared", onSelectionCleared);
    coreHandler.eventHandler.on("design:loaded", onDesignLoaded);

    setCanvas(canvas);
    setCoreHandler(coreHandler);

    return () => {
      coreHandler.eventHandler.off("selection:created", onSelectionCreated);
      coreHandler.eventHandler.off("selection:updated", onSelectionUpdated);
      coreHandler.eventHandler.off("selection:cleared", onSelectionCleared);
      coreHandler.eventHandler.off("design:loaded", onDesignLoaded);
      coreHandler.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedObject(null);
  }, [location, setSelectedObject]);

  useEffect(() => {
    savedCanvas?.setWidth(template.width);
    savedCanvas?.setHeight(template.height);
  }, [template.width, template.height, savedCanvas]);

  return (
    <div className={`relative h-full w-full`}>
      {canvasHidden && (
        <div className="flex h-full w-full items-center justify-center">
          <Loader />
        </div>
      )}
      <div className={`h-full w-full ${canvasHidden ? "hidden" : ""}`}>
        <canvas id={CANVAS_ID} />
      </div>
    </div>
  );
}

export default Canvas;
