import { fabric } from "@utils/fabric";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Color from "./shared/Color";

const DEFAULT_TEXT_BACKGROUND_COLOR = "#ffffff";

function TextBackgroundColor() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [textBackgroundColor, setTextBackgroundColor] = useState(
    (selectedObject as fabric.Textbox)?.textBackgroundColor ||
      DEFAULT_TEXT_BACKGROUND_COLOR
  );

  // triggered by clicking layers
  useEffect(() => {
    setTextBackgroundColor(
      (selectedObject as fabric.Textbox)?.textBackgroundColor ||
        DEFAULT_TEXT_BACKGROUND_COLOR
    );
  }, [selectedObject]);

  function onChange(newColor) {
    setTextBackgroundColor(newColor);
    (selectedObject as fabric.Textbox)?.set({ textBackgroundColor: newColor });

    canvas?.requestRenderAll();
  }

  return <Color color={textBackgroundColor} onChange={onChange} />;
}

export default TextBackgroundColor;
