import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AppContext } from "../../context/AppContext";
import PropertyLabel from "../Properties/shared/PropertyLabel";
import FontAlign from "./FontAlign";
import FontFamily from "./FontFamily";
import FontItalic from "./FontItalic";
import FontLinethrough from "./FontLinethrough";
import FontSize from "./FontSize";
import FontUnderline from "./FontUnderline";
import FontWeight from "./FontWeight";
import LineHeight from "./LineHeight";
import TextMode from "./TextMode";

function Font() {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [isFontWeightEnabled, setIsFontWeightEnabled] = useState(true);
  const [isFontItalicEnabled, setIsFontItalicEnabled] = useState(true);

  useEffect(() => {
    const fontFamily = (selectedObject as fabric.Textbox)?.get("fontFamily");
    const font = coreHandler?.fontHandler.getFontByName(fontFamily!);
    // TODO - do we need to check 1,700 ?
    setIsFontWeightEnabled(!!font?.variants?.includes("0,700"));
    setIsFontItalicEnabled(!!font?.variants?.includes("1,400"));
  }, [coreHandler?.fontHandler, selectedObject]);

  function onChange() {
    const fontFamily = (selectedObject as fabric.Textbox)?.get("fontFamily");
    const font = coreHandler?.fontHandler.getFontByName(fontFamily!);
    setIsFontWeightEnabled(!!font?.variants?.includes("0,700"));
    setIsFontItalicEnabled(!!font?.variants?.includes("1,400"));

    if (fontFamily) {
      coreHandler?.fontHandler
        .loadFont([fontFamily])
        .then(() => {
          canvas?.requestRenderAll();
        })
        .catch(() => {
          toast.error("Failed to load font, please re-select and try again");
        });
    }
  }

  return (
    <div className="space-y-2">
      <div>
        <PropertyLabel>Line Height</PropertyLabel>
        <LineHeight />
      </div>
      <div>
        <PropertyLabel>Font Family</PropertyLabel>
        <FontFamily onChange={onChange} />
      </div>
      <div>
        <PropertyLabel>Font Size</PropertyLabel>
        <FontSize />
      </div>
      <div>
        <PropertyLabel>Font Properties</PropertyLabel>
        <div className="flex justify-between">
          <FontWeight onChange={onChange} disabled={!isFontWeightEnabled} />
          <FontItalic onChange={onChange} disabled={!isFontItalicEnabled} />
          <FontUnderline />
          <FontLinethrough />
        </div>
      </div>
      <div className="flex justify-between">
        <FontAlign />
      </div>
      <div className="space-y-2">
        <TextMode />
      </div>
    </div>
  );
}

export default Font;
