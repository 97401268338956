import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { BoxArrowInRight, Send } from "react-bootstrap-icons";
import { sendEmailVerification } from "@utils/firebase";
import Header from "@components/Header";
import { AuthContext } from "@base/context/AuthContext";
import { ROUTE_LOGIN } from "@constants/routes";

const ICON_SIZE = 16;

function ConfirmEmail() {
  const user = useContext(AuthContext);
  const [isSending, setIsSending] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const isResendButtonDisabled = isSending || countdown > 0;
  let countdownId;

  function startCountdown(seconds = 60) {
    setCountdown(seconds);
    countdownId = setInterval(() => {
      setCountdown((prev) => {
        const next = prev - 1;
        if (next === 0) {
          clearInterval(countdownId);
        }
        return next;
      });
    }, 1000);
  }

  async function onClickToResendEmail() {
    if (isSending) {
      return;
    }

    if (user) {
      try {
        setIsSending(true);
        startCountdown(60);
        await sendEmailVerification(user);
        toast.success("Resent successfully");
      } catch (error) {
        toast.error((error as Error).message);
      }
    } else {
      toast.error("No user credential found. Please try to login again.");
    }
    setIsSending(false);
  }

  return (
    <div className="login-page h-full">
      <Header />
      <div className="h-[calc(100%-73px)]">
        <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
          <div className="text-center">
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              Thanks for signing up!
            </h1>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Please check your inbox and click on the link in the email to
              verify your email address.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <button
                className={`inline-flex items-center space-x-2 rounded border-0 bg-indigo-500 py-1 px-3 text-white hover:bg-indigo-600 focus:outline-none disabled:opacity-50`}
                disabled={isResendButtonDisabled}
                onClick={onClickToResendEmail}
              >
                <Send size={ICON_SIZE} />
                {countdown > 0 && <span>Resend after {countdown} seconds</span>}
                {countdown === 0 && <span>Not received? Resend again</span>}
              </button>
              <span>or</span>
              <Link to={ROUTE_LOGIN}>
                <button className="mt-4 inline-flex items-center space-x-2 rounded border-0 bg-gray-100 py-1 px-3 hover:bg-gray-200 focus:outline-none md:mt-0">
                  <BoxArrowInRight size={ICON_SIZE} />
                  <span>Login</span>
                </button>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ConfirmEmail;
