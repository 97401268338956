import { useState } from "react";
import { TextChange as TextChangeType } from "@eragonj/copicake-fabric/dist/types/utils";

interface IProps {
  change: TextChangeType;
  onValueUpdated: (change: TextChangeType) => void;
}

function TextChange({ change, onValueUpdated }: IProps) {
  const [name, setName] = useState(change.name);
  const [text, setText] = useState(change.text);
  const [fill, setFill] = useState(change.fill);

  return (
    <>
      <div className="flex space-x-2">
        <div className="w-full">
          <span className="text-sm">Name</span>
          <span className="px-2 text-xs font-bold text-red-500">
            (Required)
          </span>
        </div>
        <div className="w-full">
          <span className="text-sm">Text</span>
          <span className="px-2 text-xs font-bold text-gray-400">
            (Optional)
          </span>
        </div>
        <div className="w-full">
          <span className="text-sm">fill</span>
          <span className="px-2 text-xs font-bold text-gray-400">
            (Optional)
          </span>
        </div>
      </div>
      <div className="flex space-x-2">
        <input
          className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            onValueUpdated({ ...change, name: e.target.value });
          }}
        />

        <input
          className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="text"
          placeholder="Text"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            onValueUpdated({ ...change, text: e.target.value });
          }}
        />

        <input
          className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="text"
          placeholder="#cccccc"
          value={fill}
          onChange={(e) => {
            setFill(e.target.value);
            onValueUpdated({ ...change, fill: e.target.value });
          }}
        />
      </div>
    </>
  );
}

export default TextChange;
