import { useState } from "react";
import { ImageChange as ImageChangeType } from "@eragonj/copicake-fabric/dist/types/utils";

interface IProps {
  change: ImageChangeType;
  onValueUpdated: (change: ImageChangeType) => void;
}

function ImageChange({ change, onValueUpdated }: IProps) {
  const [name, setName] = useState(change.name);
  const [src, setSrc] = useState(change.src);

  return (
    <>
      <div className="flex space-x-2">
        <div className="w-full">
          <span className="text-sm">Name</span>
          <span className="px-2 text-xs font-bold text-red-500">
            (Required)
          </span>
        </div>
        <div className="w-full">
          <span className="text-sm">Src</span>
          <span className="px-2 text-xs font-bold text-gray-400">
            (Optional)
          </span>
        </div>
      </div>
      <div className="flex space-x-2">
        <input
          className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            onValueUpdated({ ...change, name: e.target.value });
          }}
        />

        <input
          className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="text"
          placeholder="Src"
          value={src}
          onChange={(e) => {
            setSrc(e.target.value);
            onValueUpdated({ ...change, src: e.target.value });
          }}
        />
      </div>
    </>
  );
}

export default ImageChange;
