import FontFaceObserver from "fontfaceobserver";
import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";
import { Font, Variant, FourFonts } from "react-fontpicker-ts";
import googleFontsInfo from "@utils/font/google-fonts-info.json";
import { DEFAULT_FONT_FAMILY, DEFAULT_FONT_OBJECT } from "@utils/constant";

class FontHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
  }

  initialize(): void {}

  destroy(): void {}

  toString(v: Variant) {
    if (typeof v === "string") {
      return v;
    }
    return (v.italic ? "1" : "0") + "," + v.weight;
  }

  getFontByName(name: string): Font | null {
    let found: Font | null = null;
    if (name === DEFAULT_FONT_FAMILY) {
      return DEFAULT_FONT_OBJECT;
    }

    (googleFontsInfo as Font[]).forEach((font) => {
      if (font.name === name.trim()) {
        found = font;
      }
    });
    return found;
  }

  getFourVariants(variants: string[]) {
    const regularWeights = variants
      .filter((v: string) => v.substring(0, 2) === "0,")
      .map((v: string) => parseInt(v.substring(2)))
      .sort((a, b) => a - b);
    const italicWeights = variants
      .filter((v: string) => v.substring(0, 2) === "1,")
      .map((v: string) => parseInt(v.substring(2)))
      .sort((a, b) => a - b);

    const fourFonts: FourFonts = {};

    // Best regular font is whatever is closest to 400 (but use 300 if only 300 and 500 available)
    fourFonts.regular = regularWeights
      .sort((a, b) => Math.abs(399 - a) - Math.abs(399 - b))
      .shift();

    // Best bold font is whatever is larger than regular, and closest to 700
    fourFonts.bold = regularWeights
      .filter((v) => v > (fourFonts.regular || 0))
      .sort((a, b) => Math.abs(700 - a) - Math.abs(700 - b))
      .shift();

    // Same for italics
    fourFonts.italic = italicWeights
      .sort((a, b) => Math.abs(399 - a) - Math.abs(399 - b))
      .shift();
    fourFonts.boldItalic = italicWeights
      .filter((v) => v > (fourFonts.italic || 0))
      .sort((a, b) => Math.abs(700 - a) - Math.abs(700 - b))
      .shift();

    const fourVariants: string[] = [];
    if (fourFonts.regular) {
      fourVariants.push("0," + fourFonts.regular);
    }
    if (fourFonts.bold) {
      fourVariants.push("0," + fourFonts.bold);
    }
    if (fourFonts.italic) {
      fourVariants.push("1," + fourFonts.italic);
    }
    if (fourFonts.boldItalic) {
      fourVariants.push("1," + fourFonts.boldItalic);
    }
    return fourVariants;
  }

  loadFontFromObject(font: Font, variants: Variant[] = []) {
    // Note: not sure why, but can't get Times New Roman to load on production, so skip it because
    // it's basically installed in everyone's laptop by default
    if (font.name === DEFAULT_FONT_FAMILY) {
      return Promise.resolve();
    }

    if (variants?.length > 0) {
      variants = font.variants.filter((v: Variant) => variants.includes(v));
    } else {
      variants = this.getFourVariants(
        font.variants.map((v) => this.toString(v))
      );
    }

    let cssId = "google-font-" + font.sane;
    const cssIdAll = cssId + "-all";
    if (variants.length === font.variants.length) {
      cssId = cssIdAll;
    } else {
      cssId +=
        "-" +
        variants.sort().join("-").replaceAll("1,", "i").replaceAll("0,", "");
    }

    const existing = document.getElementById(cssId);
    const existingAll = document.getElementById(cssIdAll);
    if (!existing && !existingAll && font?.name && variants?.length > 0) {
      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.id = cssId;
      link.href =
        "https://fonts.googleapis.com/css2?family=" +
        font.name +
        ":ital,wght@" +
        variants.sort().join(";") +
        "&display=swap";
      link.setAttribute("data-testid", cssId); // for react testing library
      document.head.appendChild(link);
    }

    let fontFaceOptions: { weight: number; style: string }[] = [];
    variants.forEach((variant) => {
      if (variant === "0,400") {
        fontFaceOptions.push({
          weight: 400,
          style: "normal",
        });
      } else if (variant === "0,700") {
        fontFaceOptions.push({
          weight: 700,
          style: "normal",
        });
      } else if (variant === "1,400") {
        fontFaceOptions.push({
          weight: 400,
          style: "italic",
        });
      } else if (variant === "1,700") {
        fontFaceOptions.push({
          weight: 700,
          style: "italic",
        });
      }
    });

    const promises = fontFaceOptions.map((fontFaceOption) => {
      return new FontFaceObserver(font.name, fontFaceOption).load();
    });

    return Promise.all(promises);
  }

  loadFontByName(font: string | Font, variants: Variant[] = []) {
    if (font === "") {
      return;
    }
    let loaded: string | Font | null = font;
    if (typeof font === "string") {
      loaded = this.getFontByName(font);
    }
    if (
      loaded === null ||
      typeof loaded !== "object" ||
      typeof loaded.sane !== "string"
    ) {
      console.error("Unknown font", font);
    } else if (loaded.variants.length < 1) {
      console.error("No valid variants of font", variants);
    } else {
      return this.loadFontFromObject(loaded, variants);
    }
  }

  loadFont(names: string[]) {
    const promises = names.map((name) => {
      return this.loadFontByName(name);
    });

    return Promise.all(promises);
  }
}

export default FontHandler;
