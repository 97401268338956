import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import Color from "./shared/Color";

const DEFAULT_FILL = "#ffffff";

function Fill() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [fill, setFill] = useState(
    (selectedObject?.get("fill") as string) || DEFAULT_FILL
  );

  // triggered by clicking layers
  useEffect(() => {
    setFill((selectedObject?.get("fill") as string) || DEFAULT_FILL);
  }, [selectedObject]);

  function onChange(newColor) {
    setFill(newColor);
    selectedObject?.set({ fill: newColor });
    canvas?.renderAll();
  }

  return <Color color={fill} onChange={onChange} />;
}

export default Fill;
