import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getOverview } from "@apis/overview";
import { AuthContext } from "../context/AuthContext";
import { DOCS_URL } from "@utils/constant";
import { AppContext } from "../context/AppContext";
import { getPlanFromPayment, PLAN } from "@utils/payment";
import Header from "@components/Header";
import UpdatePlanModal from "@components/Modals/UpdatePlanModal";
import CopyButton from "@components/shared/CopyButton";
import Stats from "@components/Profile/Stats";

function Profile() {
  const userAuth = useContext(AuthContext);
  const { user } = useContext(AppContext);
  const [overview, setOverview] = useState({
    totalRenderingCount: "Loading ...",
    totalTemplateCount: "Loading ...",
    monthlyRenderingCount: "Loading ...",
  });
  const [isShowingUpdatePlanModal, setIsShowingUpdatePlanModal] =
    useState(false);
  const payment = user?.payment;
  const plan = getPlanFromPayment(payment);
  const templateLimit = plan.TEMPLATE_LIMIT;
  const monthlyRenderingLimit = plan.RENDERING_LIMIT;
  const isNotFreePlan = plan !== PLAN.FREE;

  useEffect(() => {
    (async () => {
      try {
        const { data } = await getOverview();
        if (data) {
          const {
            totalRenderingCount,
            totalTemplateCount,
            monthlyRenderingCount,
          } = data;
          setOverview({
            totalRenderingCount: `${totalRenderingCount}`,
            totalTemplateCount: `${totalTemplateCount}`,
            monthlyRenderingCount: `${monthlyRenderingCount}`,
          });
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div className="h-full">
      <Header />
      <div className="h-[calc(100%-73px)]">
        <div className="p-5">
          <h1 className="mb-4 text-2xl font-bold">Profile</h1>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-4xl space-y-8">
              <div>
                <div className="border-b border-gray-200 pb-5">
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    Stats
                  </h3>
                </div>
                <Stats
                  overview={{
                    ...overview,
                    monthlyRenderingLimit,
                    templateLimit,
                  }}
                />
              </div>
              <div>
                <div className="border-b border-gray-200 pb-5">
                  <h3 className="text-lg font-semibold leading-6 text-gray-900">
                    Information
                  </h3>
                </div>
                <div className="mt-5 space-y-5 sm:mt-8 sm:space-y-8">
                  <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Email
                    </label>
                    <div className="mt-2 sm:col-span-4 sm:mt-0">
                      <input
                        type="text"
                        name="email"
                        id="email"
                        className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:max-w-xs sm:text-sm sm:leading-6"
                        disabled
                        value={userAuth?.email || ""}
                      />
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
                    <label
                      htmlFor="api-key"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      API Key
                    </label>
                    <div className="mt-2 sm:col-span-4 sm:mt-0">
                      <div className="flex items-center">
                        <input
                          type="text"
                          name="api-key"
                          id="api-key"
                          className="block w-full max-w-lg rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-500 disabled:shadow-none sm:max-w-xs sm:text-sm sm:leading-6"
                          value={user?.api_key}
                          disabled
                        />
                        <div className="ml-2">
                          <CopyButton
                            text={user?.api_key || ""}
                            onCopy={() => {
                              toast.success("Copied your API key!");
                            }}
                          />
                        </div>
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        You need to use this key to call the API. For details,
                        please check{" "}
                        <a href={DOCS_URL} className="text-blue-500">
                          our docs
                        </a>
                        .
                      </p>
                    </div>
                  </div>
                  <div className="sm:grid sm:grid-cols-5 sm:items-start sm:gap-4">
                    <label
                      htmlFor="plan"
                      className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5"
                    >
                      Plan
                    </label>
                    <div className="mt-2 sm:col-span-4 sm:mt-0">
                      <div className="flex items-center">
                        <button
                          className="block rounded-md py-2 px-4 text-center text-sm font-semibold text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                          onClick={() => {
                            setIsShowingUpdatePlanModal(true);
                          }}
                        >
                          <span>{plan.TITLE}</span>
                        </button>
                      </div>
                      {isNotFreePlan &&
                        payment?.status === "active" &&
                        payment?.next_bill_date && (
                          <p className="mt-2 text-sm text-gray-500">
                            Your next billing date is{" "}
                            <span className="underline">
                              {payment?.next_bill_date}
                            </span>
                          </p>
                        )}
                      {isNotFreePlan &&
                        payment?.status === "deleted" &&
                        payment?.cancellation_effective_date && (
                          <div>
                            <p className="mt-2 text-sm text-gray-500">
                              You already cancelled your plan but you can still
                              use it until{" "}
                              <span className="underline">
                                {payment?.cancellation_effective_date}
                              </span>
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpdatePlanModal
        open={isShowingUpdatePlanModal}
        onClose={() => {
          setIsShowingUpdatePlanModal(false);
        }}
      />
    </div>
  );
}

export default Profile;
