import React from "react";
import { fabric } from "@utils/fabric";
import CoreHandler from "../handlers/CoreHandler";
import { Template, User } from "../type";

export const AppContext = React.createContext({
  canvas: null as fabric.Canvas | null,
  selectedObject: null as fabric.Object | null,
  setCanvas: (canvas: fabric.Canvas) => {},
  setSelectedObject: (object: fabric.Object | null) => {},
  coreHandler: null as CoreHandler | null,
  setCoreHandler: (coreHandler: CoreHandler) => {},
  templates: [] as Template[],
  setTemplates: (
    templates: Template[] | ((prevState: Template[]) => Template[])
  ) => {},
  user: null as User | null,
  setUser: (user: User) => {},
  isLayerOpened: true,
  setIsLayerOpened: (to: boolean | ((prev: boolean) => boolean)) => {},
  isWidthHeightProportionalScaled: false,
  setIsWidthHeightProportionalScaled: (
    to: boolean | ((prev: boolean) => boolean)
  ) => {},
  isAppInitialized: false,
  setIsAppInitialized: (isAppInitialized: boolean) => {},
});
