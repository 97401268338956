import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function Angle() {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [angle, setAngle] = useState(Math.round(selectedObject?.angle || 0));

  // triggered by clicking layers
  useEffect(() => {
    setAngle(selectedObject?.angle || 0);
  }, [selectedObject]);

  // triggered by fabric controls
  useEffect(() => {
    function onModified(event: any) {
      setAngle(selectedObject?.angle || 0);
    }

    coreHandler?.eventHandler.on("object:modified", onModified);
    return () => {
      coreHandler?.eventHandler.off("object:modified", onModified);
    };
  }, [coreHandler, selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.round(parseInt(event.target.value, 10));
    setAngle(value);
    selectedObject?.rotate(value);
    canvas?.renderAll();
  }

  return (
    <input
      type="number"
      onChange={onChange}
      value={angle}
      min={0}
      max={359}
      className="h-8 w-full rounded"
    />
  );
}

export default Angle;
