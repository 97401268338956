import Icon from "../../shared/Icon";
import { TemplateItemInfo } from "./templates";

function TemplateItem({
  data,
  onSelect,
  selected = false,
}: {
  data: TemplateItemInfo;
  onSelect: (data: TemplateItemInfo) => void;
  selected: boolean;
}) {
  let rotate = data.label === "Horizontal" ? "rotate-90" : "";
  let iconColor = "text-black";
  switch (data.icon) {
    case "Facebook":
      iconColor = "text-[#3b5998]";
      break;
    case "Twitter":
      iconColor = "text-[#1da1f2]";
      break;
    case "Instagram":
      iconColor = "text-[#f56040]";
      break;
    case "Youtube":
      iconColor = "text-[#ff0000]";
      break;
    case "Linkedin":
      iconColor = "text-[#0a66c2]";
      break;
    case "Pinterest":
      iconColor = "text-[#e60023]";
      break;
    default:
      iconColor = "text-black";
      break;
  }

  return (
    <div
      className={`mr-2 mb-2 flex h-40 w-40 cursor-pointer flex-col items-center justify-center rounded-sm border-2 text-center ${
        selected ? "border-blue-500" : "border-slate-200"
      }`}
      onClick={() => {
        onSelect(data);
      }}
    >
      <div className={`h-30 mb-2 ${rotate} ${iconColor}`}>
        <Icon size={24} iconName={data.icon} />
      </div>
      <div className="text-sm">{data.label}</div>
    </div>
  );
}

export default TemplateItem;
