import { fabric } from "@utils/fabric";
import {
  TextLeft,
  TextRight,
  TextCenter,
  Justify,
} from "react-bootstrap-icons";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function FontAlign() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [textAlign, setTextAlign] = useState(
    (selectedObject as fabric.Textbox)?.textAlign
  );

  // triggered by clicking layers
  useEffect(() => {
    setTextAlign((selectedObject as fabric.Textbox)?.textAlign);
  }, [selectedObject]);

  function onClick(newTextAlign: string) {
    if (textAlign === newTextAlign) {
      setTextAlign("");
      (selectedObject as fabric.Textbox)?.set({ textAlign: "" });
    } else {
      setTextAlign(newTextAlign);
      (selectedObject as fabric.Textbox)?.set({ textAlign: newTextAlign });
    }
    canvas?.renderAll();
  }

  return (
    <>
      <button
        className={`rounded border border-gray-500 p-2 ${
          textAlign === "left" ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick.bind(null, "left")}
      >
        <TextLeft size={16} />
      </button>
      <button
        className={`rounded border border-gray-500 p-2 ${
          textAlign === "center" ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick.bind(null, "center")}
      >
        <TextCenter size={16} />
      </button>
      <button
        className={`rounded border border-gray-500 p-2 ${
          textAlign === "right" ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick.bind(null, "right")}
      >
        <TextRight size={16} />
      </button>
      <button
        className={`rounded border border-gray-500 p-2 ${
          textAlign === "justify" ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick.bind(null, "justify")}
      >
        <Justify size={16} />
      </button>
    </>
  );
}

export default FontAlign;
