import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";
import nanoid from "@utils/nanoid";
import { api } from "../constants";

class ServerAPIHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
  }

  initialize(): void {}

  destroy(): void {}

  async uploadImageToS3(file: File): Promise<string> {
    const fileExtension = file.name.split(".").pop();
    const filename = `${nanoid(20)}.${fileExtension}`;
    const res = await fetch(
      `${api.INTERNAL_SERVER_URL}/upload-image-to-s3?file=${filename}`
    );

    const { url, fields } = await res.json();
    const formData = new FormData();

    Object.entries({ ...fields, file }).forEach(([key, value]) => {
      formData.append(key, value as string);
    });

    const result = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (result.ok) {
      const imageUrl = `${result.url}/${fields.key}`;
      return Promise.resolve(imageUrl);
    } else {
      return Promise.reject("failed to upload image to s3");
    }
  }
}

export default ServerAPIHandler;
