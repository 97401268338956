import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";
import PropertyLabel from "../Properties/shared/PropertyLabel";
import PropertyHint from "./shared/PropertyHint";
import NumberInput from "./shared/NumberInput";

function TextMode() {
  const { canvas, selectedObject } = useContext(AppContext);

  const [textMode, setTextMode] = useState(
    (selectedObject as fabric.Textbox).get("textMode") ||
      fabric.Textbox.prototype.textMode
  );

  const [maxLines, setMaxLines] = useState(
    (selectedObject as fabric.Textbox).get("maxLines") ||
      fabric.Textbox.prototype.maxLines
  );

  const [showMaxLines, setShowMaxLines] = useState(false);

  // triggered by clicking layers
  useEffect(() => {
    const maxLinesValue =
      (selectedObject as fabric.Textbox).get("maxLines") ||
      fabric.Textbox.prototype.maxLines;

    const textModeValue =
      (selectedObject as fabric.Textbox).get("textMode") ||
      fabric.Textbox.prototype.textMode;

    setTextMode(textModeValue);
    setMaxLines(maxLinesValue);
  }, [selectedObject]);

  useEffect(() => {
    if (textMode === "autofit") {
      setShowMaxLines(false);
    } else {
      setShowMaxLines(true);
    }
  }, [textMode]);

  function onTextModeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const value = event.target.value as "autofit" | "truncate";
    setTextMode(value);
    (selectedObject as fabric.Textbox).set({ textMode: value });
    canvas?.renderAll();
  }

  function onMaxLinesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.max(0, parseInt(event.target.value, 10));
    setMaxLines(value);
    (selectedObject as fabric.Textbox).set({ maxLines: value });
    canvas?.renderAll();
  }

  return (
    <>
      <div>
        <PropertyLabel>Text Mode</PropertyLabel>
        <select
          onChange={onTextModeChange}
          value={textMode}
          className="w-full rounded"
        >
          <option value="autofit">autofit</option>
          <option value="truncate">truncate</option>
        </select>
        <PropertyHint>
          <div className="space-y-1">
            <div>
              <span className="font-bold underline">autofit</span> will
              automatically adjust the font size to fit the text within the
              textbox.
              <br />
            </div>
            <div>
              <span className="font-bold underline">truncate</span> will display
              the text in a single line and hide the overflowed text.
            </div>
          </div>
        </PropertyHint>
      </div>
      {showMaxLines && (
        <div>
          <PropertyLabel>Line Clamp</PropertyLabel>
          <NumberInput
            onChange={onMaxLinesChange}
            value={maxLines}
            min={0}
            step={1}
            className="h-8 w-full rounded"
          />
          <PropertyHint>
            Limit the number of lines of text to display. Set to 0 to disable.
            After setting up, you can see how it works in the render section.
          </PropertyHint>
        </div>
      )}
    </>
  );
}

export default TextMode;
