import Copicake from "@copicake/copicake-js";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { ObjectChange } from "@eragonj/copicake-fabric/dist/types/utils";
import Header from "@components/Header";
import Change from "@components/Playground/Change";
import { AppContext } from "../context/AppContext";

const DEFAULT_PLACEHOLDER = "__DEFAULT__";
const PLACEHOLDER_IMAGE_URL = `${process.env.PUBLIC_URL}/placeholder.png`;

const defaultTextChange: ObjectChange = {
  name: "",
  text: DEFAULT_PLACEHOLDER,
  fill: DEFAULT_PLACEHOLDER,
};

const defaultImageChange: ObjectChange = {
  name: "",
  src: DEFAULT_PLACEHOLDER,
};

function Playground() {
  const { user } = useContext(AppContext);
  const [isCreating, setIsCreating] = useState(false);
  const [apiKey, setApiKey] = useState(user?.api_key || "");
  const [imageUrl, setImageUrl] = useState(PLACEHOLDER_IMAGE_URL);
  const [templateId, setTemplateId] = useState("");
  const [changes, setChanges] = useState<ObjectChange[]>([
    defaultTextChange,
    defaultImageChange,
  ]);

  function areChangesValid(changes: ObjectChange[]): boolean {
    return changes.every((change) => {
      return change.name && change.name.length > 0;
    });
  }

  function getFilteredChanges(changes: ObjectChange[]): ObjectChange[] {
    return changes.map((change) => {
      const cloned = { ...change };

      if ("text" in cloned && cloned.text === DEFAULT_PLACEHOLDER) {
        delete cloned.text;
      }

      if ("fill" in cloned && cloned.fill === DEFAULT_PLACEHOLDER) {
        delete cloned.fill;
      }

      if ("src" in cloned && cloned.src === DEFAULT_PLACEHOLDER) {
        delete cloned.src;
      }

      return cloned;
    });
  }

  async function onSubmit() {
    if (isCreating) {
      return;
    }

    if (!apiKey) {
      toast.error("Please enter your API key");
      return;
    }

    if (!templateId) {
      toast.error("Please enter your template id");
      return;
    }

    if (!areChangesValid(changes)) {
      toast.error("Please fill required parameters");
      return;
    }

    setIsCreating(true);

    const options = {
      template_id: templateId,
      changes: getFilteredChanges(changes),
    };

    const toastId = toast.loading("Creating image...");

    try {
      const copicake = new Copicake({ apiKey });
      const rendering = await copicake.image.create(options);
      toast.update(toastId, { render: "Retrieving image..." });

      const finalRendering = await copicake.image.getUntilFinished(
        rendering.id
      );

      if (finalRendering.status === "success") {
        setImageUrl(finalRendering.permanent_url);
        toast.update(toastId, {
          render: "Image created!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
        });
      } else {
        throw new Error("failed to render");
      }
    } catch (error) {
      console.error(error);
      toast.update(toastId, {
        render: (error as Error).message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    }

    setIsCreating(false);
  }

  return (
    <div className="h-full">
      <Header />
      <div className="h-[calc(100%-73px-81px)] h-full">
        <div className="flex h-full flex-wrap items-center justify-around">
          <div className="h-full w-full overflow-auto p-5 md:w-1/2">
            <div className="mb-8">
              <h1 className="mb-2 text-2xl font-bold">Playground</h1>
              <p>
                We provided a playground for you to try Copicake and see how it
                works!
              </p>
              <p>
                Please try to create a template first and fill the following
                fields based on your content :)
              </p>
            </div>
            <div className="mb-4">
              <label className="text-md mb-2 block font-bold text-gray-700">
                <span>API Token</span>
                <span className="px-2 text-xs text-red-500">(Required)</span>
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                type="text"
                placeholder="Your API Key"
                value={apiKey}
                onChange={(e) => {
                  setApiKey(e.target.value);
                }}
              />
            </div>
            <div className="mb-4">
              <label className="text-md mb-2 block font-bold text-gray-700">
                <span>Template Id</span>
                <span className="px-2 text-xs text-red-500">(Required)</span>
              </label>
              <input
                className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                type="text"
                placeholder="Your Template Id"
                value={templateId}
                onChange={(e) => {
                  setTemplateId(e.target.value);
                }}
              />
            </div>
            {changes.map((change, index) => {
              return (
                <Change
                  key={index}
                  change={change}
                  onValueUpdated={(newChange) => {
                    const newChanges = [...changes];
                    newChanges[index] = newChange;
                    setChanges(newChanges);
                  }}
                  onRemove={() => {
                    const newChanges = [...changes];
                    newChanges.splice(index, 1);
                    setChanges(newChanges);
                  }}
                />
              );
            })}
            <div className="space-y-2">
              <div className="text-xs font-bold text-gray-400">
                Note 1: Put "__DEFAULT__" if you want to use the default value
                in your template.
              </div>
              <div className="text-xs font-bold text-gray-400">
                Note 2: This playground is designed mainly for demonstration
                purpose. If you want to do more customizations for your
                template, please check the "Preview section" in the design
                editor :)
              </div>
            </div>
          </div>
          <div className="relative flex h-full w-full flex-col items-center justify-center bg-gray-100 md:w-1/2">
            <div className="h-1/2 w-1/2">
              <div
                className="h-full w-full bg-contain bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${imageUrl})` }}
              ></div>
            </div>
            <div className="absolute top-4 right-5 rounded-md border bg-white py-1 px-3 text-sm font-bold">
              <span>Live Preview</span>
              <span className="absolute -top-0.5 -right-0.5 flex h-2 w-2">
                <span className="absolute inline-flex h-full w-full animate-ping rounded-full bg-pink-400 opacity-75"></span>
                <span className="relative inline-flex h-2 w-2 rounded-full bg-pink-500"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t p-5">
        <div className="flex justify-end space-x-2">
          <button
            onClick={() => {
              setChanges((prevChanges) => {
                return [...prevChanges, defaultTextChange];
              });
            }}
            className="focus:shadow-outline rounded bg-gray-100 py-2 px-4 hover:bg-gray-200 focus:outline-none"
            type="button"
            disabled={isCreating}
          >
            Add Text Change
          </button>
          <button
            onClick={() => {
              setChanges((prevChanges) => {
                return [...prevChanges, defaultImageChange];
              });
            }}
            className="focus:shadow-outline rounded bg-gray-100 py-2 px-4 hover:bg-gray-200 focus:outline-none"
            type="button"
            disabled={isCreating}
          >
            Add Image Change
          </button>
          <button
            className="focus:shadow-outline rounded bg-indigo-500 py-2 px-4 font-bold text-white hover:bg-indigo-600 focus:outline-none"
            type="button"
            onClick={onSubmit}
            disabled={isCreating}
          >
            Render (Count as 1 rendering)
          </button>
        </div>
      </div>
    </div>
  );
}

export default Playground;
