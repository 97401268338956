import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./styles/default.css";
import "react-toastify/dist/ReactToastify.css";
import "react-fontpicker-ts/dist/index.css";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./provider/AuthProvider";
import { AppProvider } from "./provider/AppProvider";
import { RollbarProvider } from "./provider/RollbarProvider";

ReactDOM.render(
  <React.StrictMode>
    <RollbarProvider>
      <AppProvider>
        <AuthProvider>
          <BrowserRouter>
            <App />
            <ToastContainer
              position="bottom-right"
              autoClose={1000}
              hideProgressBar={true}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </BrowserRouter>
        </AuthProvider>
      </AppProvider>
    </RollbarProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
