import Header from "@components/Header";
import { Stars } from "react-bootstrap-icons";

function UpgradePage() {
  return (
    <div className="h-full">
      <Header />
      <div className="relative h-[calc(100%-73px)]">
        <div className="flex h-full items-center">
          <div className="mx-auto max-w-2xl lg:text-center">
            <h2 className="text-base font-semibold leading-7 text-indigo-600">
              <Stars className="inline-block h-6 w-6" />
              Upgrade Needed
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              This particular feature is exclusively accessible to users with a
              paid subscription. To gain access, simply navigate to your profile
              page and proceed to upgrade your current plan :)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradePage;
