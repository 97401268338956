import { useContext, useEffect, ReactNode, useState } from "react";
import { AppContext } from "@context/AppContext";
import { DEFAULT_WORKSPACE_MODE, WORKSPACE_ID } from "@utils/constant";
import { Template } from "@base/type";
import {
  ZoomIn,
  ZoomOut,
  ArrowsFullscreen,
  Cursor,
  HandIndexThumb,
} from "react-bootstrap-icons";

interface Props {
  children?: ReactNode;
  template: Template;
  [otherProps: string]: any;
}

const ICON_SIZE = 16;

function Workspace({ children, template, ...otherProps }: Props) {
  const { coreHandler } = useContext(AppContext);
  const [currentMode, setCurrentMode] = useState(DEFAULT_WORKSPACE_MODE);

  const onModeChange = (mode: string) => {
    setCurrentMode(mode);
  };

  function onViewClick() {
    coreHandler?.workspaceHandler.setMode("view");
  }

  function onPanClick() {
    coreHandler?.workspaceHandler.setMode("pan");
  }

  function onZoomInClick() {
    coreHandler?.workspaceHandler.zoomIn();
  }

  function onZoomOutClick() {
    coreHandler?.workspaceHandler.zoomOut();
  }

  function onZoomAutoClick() {
    coreHandler?.workspaceHandler.zoomAuto();
  }

  useEffect(() => {
    coreHandler?.workspaceHandler.on("mode:change", onModeChange);
    return () => {
      coreHandler?.workspaceHandler.off("mode:change", onModeChange);
    };
  }, [coreHandler?.workspaceHandler]);

  useEffect(() => {
    function onDesignLoaded() {
      coreHandler?.workspaceHandler.initializeAfterWorkspaceDomIsReady(
        template
      );
    }

    coreHandler?.eventHandler.on("design:loaded", onDesignLoaded);
    return () => {
      coreHandler?.eventHandler.off("design:loaded", onDesignLoaded);
      coreHandler?.workspaceHandler.destroy();
    };
  }, [coreHandler, template]);

  useEffect(() => {
    coreHandler?.workspaceHandler.resizeWorkspaceAfterTemplateChange(
      template.width,
      template.height
    );
  }, [template.width, template.height, coreHandler?.workspaceHandler]);

  return (
    <div
      className="relative h-full w-full bg-[#efefef]"
      id={`#${WORKSPACE_ID}`}
      {...otherProps}
    >
      <div className="absolute bottom-2 right-2 z-10 flex flex-col space-y-2">
        <button
          className="rounded border bg-white p-2 hover:bg-gray-300 disabled:bg-slate-200"
          disabled={currentMode === "view"}
          data-tip="View (v)"
          onClick={onViewClick}
        >
          <Cursor size={ICON_SIZE} />
        </button>
        <button
          className="rounded border bg-white p-2 hover:bg-gray-300 disabled:bg-slate-200"
          disabled={currentMode === "pan"}
          data-tip="Pan (p)"
          onClick={onPanClick}
        >
          <HandIndexThumb size={ICON_SIZE} />
        </button>
        <button
          className="rounded border bg-white p-2 hover:bg-gray-300"
          data-tip="Zoom In (shift + +)"
          onClick={onZoomInClick}
        >
          <ZoomIn size={ICON_SIZE} />
        </button>
        <button
          className="rounded border bg-white p-2 hover:bg-gray-300"
          data-tip="Zoom Out (shift + -)"
          onClick={onZoomOutClick}
        >
          <ZoomOut size={ICON_SIZE} />
        </button>
        <button
          className="rounded border bg-white p-2 hover:bg-gray-300"
          data-tip="Auto Zoom (shift + 0)"
          onClick={onZoomAutoClick}
        >
          <ArrowsFullscreen size={ICON_SIZE} />
        </button>
      </div>
      {children}
    </div>
  );
}

export default Workspace;
