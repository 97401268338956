import { fabric } from "@utils/fabric";
import { TypeStrikethrough } from "react-bootstrap-icons";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function FontLinethrough() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [linethrough, setLinethrough] = useState(
    (selectedObject as fabric.Textbox)?.linethrough
  );

  // triggered by clicking layers
  useEffect(() => {
    setLinethrough((selectedObject as fabric.Textbox)?.linethrough);
  }, [selectedObject]);

  function onClick() {
    setLinethrough(!linethrough);
    (selectedObject as fabric.Textbox)?.set({ linethrough: !linethrough });
    canvas?.renderAll();
  }

  return (
    <div>
      <button
        className={`rounded border border-gray-500 p-2 ${
          linethrough ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick}
      >
        <TypeStrikethrough size={16} />
      </button>
    </div>
  );
}

export default FontLinethrough;
