import React, { useState } from "react";
import { toast } from "react-toastify";
import LinkImage from "./LinkImage";

function LinkTab({
  onSelect = () => {},
}: {
  onSelect: (image: string) => void;
}) {
  const [url, setUrl] = useState("");
  const [image, setImage] = useState("");

  const onClickToSearch = async () => {
    if (url === "") {
      return;
    }

    const img = document.createElement("img");
    img.onerror = () => {
      setImage("");
      toast.error("Can not load the image, please check your URL again");
    };
    img.onload = () => {
      setImage(url);
      onSelect(url);
    };
    img.src = url;
  };

  return (
    <div>
      <div className="mb-4 flex">
        <input
          className="focus:shadow-outline w-full flex-1 appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="search"
          placeholder="https://domain/image.png"
          value={url}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
              onClickToSearch();
            }
          }}
          onChange={(e) => {
            setUrl(e.target.value);
          }}
        ></input>
        <button
          type="button"
          className="mt-3 inline-flex w-full rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onClickToSearch}
        >
          Search
        </button>
      </div>
      <div className="flex h-60 flex-row flex-wrap overflow-auto rounded border-2 border-dashed p-2">
        <div className="flex h-full w-full items-center justify-center text-sm">
          {image && <LinkImage image={image} />}
          {!image && <span>No images</span>}
        </div>
      </div>
    </div>
  );
}

export default LinkTab;
