import Loader from "./shared/Loader";

function CenterLoading() {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="mr-2">
        <Loader />
      </div>
      <div>Initializing Copicake...</div>
    </div>
  );
}

export default CenterLoading;
