import React, { useState } from "react";
import { toast } from "react-toastify";
import { createApi } from "unsplash-js";
import { Basic } from "unsplash-js/dist/methods/photos/types";
import { UNSPLASH_TOKEN } from "@utils/constant";
import UnsplashImage from "./UnsplashImage";

const unsplash = createApi({
  accessKey: UNSPLASH_TOKEN,
});

function UnsplashTab({
  onSelect = () => {},
}: {
  onSelect: (image: Basic) => void;
}) {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState<Basic[]>([]);
  const [selectedImage, setSelectedImage] = useState<Basic>();

  const onClickToSearch = async () => {
    if (query === "") {
      return;
    }

    try {
      const result = await unsplash.search.getPhotos({
        query,
        page: 1,
        perPage: 20,
        orientation: "landscape",
      });
      if (result.type === "error") {
        toast.error(result.errors[0]);
      } else {
        const images = result.response.results;
        setImages(images);
      }
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  return (
    <div>
      <div className="mb-4 flex">
        <input
          className="focus:shadow-outline w-full flex-1 appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
          type="search"
          placeholder="Image title..."
          value={query}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === "Enter") {
              onClickToSearch();
            }
          }}
          onChange={(e) => {
            setQuery(e.target.value);
          }}
        ></input>
        <button
          type="button"
          className="mt-3 inline-flex w-full rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={onClickToSearch}
        >
          Search
        </button>
      </div>
      <div className="flex h-60 flex-row flex-wrap overflow-auto rounded border-2 border-dashed p-2">
        {images.length === 0 && (
          <div className="flex h-full w-full items-center justify-center text-sm">
            No images
          </div>
        )}
        {images.length !== 0 &&
          images.map((image) => {
            return (
              <UnsplashImage
                data={image}
                key={image.id}
                selected={selectedImage?.id === image.id}
                onImageClick={(image) => {
                  setSelectedImage(image);
                  onSelect(image);
                }}
              />
            );
          })}
      </div>
    </div>
  );
}

export default UnsplashTab;
