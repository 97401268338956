import { Filter } from "react-bootstrap-icons";

type Props = {
  onFilter: (keyword: string) => void;
};

function TemplateFilter({ onFilter = () => {} }: Props) {
  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    const keyword = (e.target as HTMLInputElement).value;
    onFilter(keyword);
  }

  return (
    <div className="relative">
      <input
        type="text"
        onKeyUp={onKeyDown}
        placeholder="Filter templates..."
        className="h-8 rounded border-0 ring-1 ring-inset ring-gray-300"
      />
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <Filter size={16} />
      </div>
    </div>
  );
}

export default TemplateFilter;
