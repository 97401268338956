import { FileEarmarkPlus } from "react-bootstrap-icons";

type Props = {
  onClick: () => void;
};

function AddTemplateItem({ onClick = () => {} }: Props) {
  return (
    <button
      className="inline-flex items-center rounded border-0 bg-indigo-500 py-1 px-3 text-white hover:bg-indigo-600 focus:outline-none"
      onClick={() => {
        onClick();
      }}
    >
      <FileEarmarkPlus size={16} />
    </button>
  );
}

export default AddTemplateItem;
