import { useContext, useEffect, useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { listTemplates } from "@apis/template";
import { getUser } from "@apis/user";
import { AppContext } from "../context/AppContext";
import { AuthContext } from "../context/AuthContext";
import CenterLoading from "./CenterLoading";
import { ROUTE_AUTH_VERIFY_EMAIL, ROUTE_LOGIN } from "@constants/routes";

function RequireAuth({ children }: { children: JSX.Element }) {
  const user = useContext(AuthContext);
  const { setTemplates, setUser, isAppInitialized, setIsAppInitialized } =
    useContext(AppContext);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const location = useLocation();

  useEffect(() => {
    // initial state, not sure whether we have user information or not
    if (typeof user === "undefined") {
      // do nothing
    }
    // firebase already checked user information, and can't find any
    else if (user === null) {
      setIsCheckingAuth(false);
    }
    // with user information but app is not initialized
    else if (user && !isAppInitialized) {
      (async () => {
        const result = await Promise.all([getUser(), listTemplates()]);
        setUser(result[0].data);
        setTemplates(result[1].data);
        setIsAppInitialized(true);
        setIsCheckingAuth(false);
      })();
    } else {
      // with user && isAppInitialized
      setIsCheckingAuth(false);
    }
  }, [isAppInitialized, setIsAppInitialized, setTemplates, setUser, user]);

  return (
    <>
      {isCheckingAuth && <CenterLoading></CenterLoading>}
      {!isCheckingAuth && user === null && (
        <Navigate to={ROUTE_LOGIN} state={{ from: location }} replace />
      )}
      {!isCheckingAuth && user && !user?.emailVerified && (
        <Navigate
          to={ROUTE_AUTH_VERIFY_EMAIL}
          state={{ from: location }}
          replace
        />
      )}
      {!isCheckingAuth && user?.emailVerified && children}
    </>
  );
}

export default RequireAuth;
