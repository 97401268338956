import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import NumberInput from "./shared/NumberInput";

function getTop(selectedObject: fabric.Object | null) {
  const boundingRect = selectedObject?.getBoundingRect(true);
  return boundingRect?.top || 0;
}

function Top() {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [top, setTop] = useState(getTop(selectedObject));

  // triggered by clicking layers
  useEffect(() => {
    const top = getTop(selectedObject);
    setTop(top);
  }, [selectedObject]);

  // triggered by fabric controls
  useEffect(() => {
    function onModified(event: any) {
      const top = getTop(selectedObject);
      setTop(top);
    }

    coreHandler?.eventHandler.on("object:modified", onModified);
    return () => {
      coreHandler?.eventHandler.off("object:modified", onModified);
    };
  }, [coreHandler, selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (selectedObject) {
      const value = parseInt(event.target.value, 10);
      selectedObject.set("top", value);

      canvas?.renderAll();
      setTop(value);
    }
  }

  return (
    <NumberInput
      onChange={onChange}
      value={top}
      className="h-8 w-full rounded"
    />
  );
}

export default Top;
