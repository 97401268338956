import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function Name() {
  const { selectedObject } = useContext(AppContext);
  const [name, setName] = useState(selectedObject?.get("name"));

  // triggered by clicking layers
  useEffect(() => {
    setName(selectedObject?.get("name"));
  }, [selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value !== name && value !== "") {
      setName(value);
      selectedObject?.set("name", value);
    }
  }

  return (
    <input
      type="text"
      onChange={onChange}
      value={name}
      className="h-8 w-full rounded"
    />
  );
}

export default Name;
