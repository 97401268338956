import { fabric } from "@utils/fabric";
import { TypeBold } from "react-bootstrap-icons";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function FontWeight({
  disabled = false,
  onChange = () => {},
}: {
  disabled: boolean;
  onChange: () => void;
}) {
  const { selectedObject } = useContext(AppContext);
  const [fontWeight, setFontWeight] = useState(
    (selectedObject as fabric.Textbox)?.fontWeight
  );

  // triggered by clicking layers
  useEffect(() => {
    setFontWeight((selectedObject as fabric.Textbox)?.fontWeight);
  }, [selectedObject]);

  function onClick() {
    const newFontWeight = fontWeight === "normal" ? "bold" : "normal";
    setFontWeight(newFontWeight);
    (selectedObject as fabric.Textbox)?.set({ fontWeight: newFontWeight });

    // notify parent to load font and update canvas
    onChange();
  }

  return (
    <div>
      <button
        disabled={disabled}
        className={`rounded border border-gray-500 p-2 disabled:text-slate-300 disabled:hover:cursor-not-allowed ${
          fontWeight === "bold" ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick}
      >
        <TypeBold size={16} />
      </button>
    </div>
  );
}

export default FontWeight;
