import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { MIN_PROPERTY_WIDTH } from "@utils/constant";
import NumberInput from "./shared/NumberInput";

function getScaledWidth(selectedObject: fabric.Object | null) {
  return Math.round(
    (selectedObject?.width || MIN_PROPERTY_WIDTH) *
      (selectedObject?.scaleX || 1)
  );
}

function Width() {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [width, setWidth] = useState(getScaledWidth(selectedObject));

  // triggered by clicking layers
  useEffect(() => {
    setWidth(getScaledWidth(selectedObject));
  }, [selectedObject]);

  // triggered by fabric controls
  useEffect(() => {
    function onModified(event: any) {
      const newWidth = getScaledWidth(selectedObject);
      setWidth(newWidth);
    }

    coreHandler?.eventHandler.on("object:modified", onModified);
    return () => {
      coreHandler?.eventHandler.off("object:modified", onModified);
    };
  }, [coreHandler, selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.max(
      MIN_PROPERTY_WIDTH,
      parseInt(event.target.value, 10)
    );

    selectedObject?.set(
      "scaleX",
      value / (selectedObject?.width || MIN_PROPERTY_WIDTH)
    );

    setWidth(value);
    // @ts-ignore - TODO - fix this later
    coreHandler?.eventHandler.modified({ target: selectedObject });
    canvas?.renderAll();
  }

  return (
    <NumberInput
      onChange={onChange}
      value={width}
      min={MIN_PROPERTY_WIDTH}
      className="h-8 w-full rounded"
    />
  );
}

export default Width;
