import { fabric } from "@utils/fabric";
import { TypeItalic } from "react-bootstrap-icons";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function FontItalic({
  disabled = false,
  onChange = () => {},
}: {
  disabled: boolean;
  onChange: () => void;
}) {
  const { selectedObject } = useContext(AppContext);
  const [fontStyle, setFontStyle] = useState(
    (selectedObject as fabric.Textbox)?.fontStyle
  );

  // triggered by clicking layers
  useEffect(() => {
    setFontStyle((selectedObject as fabric.Textbox)?.fontStyle);
  }, [selectedObject]);

  function onClick() {
    const newFontStyle = fontStyle === "normal" ? "italic" : "normal";
    setFontStyle(newFontStyle);
    (selectedObject as fabric.Textbox)?.set({ fontStyle: newFontStyle });

    // notify parent to load font and update canvas
    onChange();
  }

  return (
    <div>
      <button
        disabled={disabled}
        className={`rounded border border-gray-500 p-2 disabled:text-slate-300 disabled:hover:cursor-not-allowed ${
          fontStyle === "italic" ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick}
      >
        <TypeItalic size={16} />
      </button>
    </div>
  );
}

export default FontItalic;
