import { code } from "@constants/index";
import BaseHandler from "./BaseHandler";
import CoreHandler from "./CoreHandler";

class ShortcutHandler extends BaseHandler {
  public coreHandler: CoreHandler;

  constructor(coreHandler: CoreHandler) {
    super();

    this.coreHandler = coreHandler;
  }

  initialize(): void {}

  destroy(): void {}

  isSelectAll(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && event.code === code.KEY_A;
  }

  isReload(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && event.code === code.KEY_R;
  }

  isCopy(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && event.code === code.KEY_C;
  }

  isPaste(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && event.code === code.KEY_V;
  }

  isUndo(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && event.code === code.KEY_Z;
  }

  isSave(event: KeyboardEvent): boolean {
    return (event.ctrlKey || event.metaKey) && event.code === code.KEY_S;
  }

  isRedo(event: KeyboardEvent): boolean {
    return (
      (event.ctrlKey || event.metaKey) &&
      event.shiftKey &&
      event.code === code.KEY_Z
    );
  }

  isZoomIn(event: KeyboardEvent): boolean {
    return event.shiftKey && event.code === code.KEY_EQUAL;
  }

  isZoomOut(event: KeyboardEvent): boolean {
    return event.shiftKey && event.code === code.KEY_MINUS;
  }

  isZoomAuto(event: KeyboardEvent): boolean {
    return event.shiftKey && event.code === code.KEY_0;
  }

  isBringForward(event: KeyboardEvent): boolean {
    return event.code === code.BRACKET_LEFT;
  }

  isSendBackward(event: KeyboardEvent): boolean {
    return event.code === code.BRACKET_RIGHT;
  }

  isEscape(event: KeyboardEvent): boolean {
    return event.code === code.ESCAPE;
  }

  isBackspace(event: KeyboardEvent): boolean {
    return event.code === code.BACKSPACE;
  }

  isEnter(event: KeyboardEvent): boolean {
    return event.code === code.ENTER;
  }

  isC(event: KeyboardEvent): boolean {
    return event.code === code.KEY_C;
  }

  isR(event: KeyboardEvent): boolean {
    return event.code === code.KEY_R;
  }

  isA(event: KeyboardEvent): boolean {
    return event.code === code.KEY_A;
  }

  isT(event: KeyboardEvent): boolean {
    return event.code === code.KEY_T;
  }

  isV(event: KeyboardEvent): boolean {
    return event.code === code.KEY_V;
  }

  isP(event: KeyboardEvent): boolean {
    return event.code === code.KEY_P;
  }
}

export default ShortcutHandler;
