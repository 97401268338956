import { useContext } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { getPlanFromPayment, PLAN } from "@utils/payment";
import { ROUTE_UPGRADE } from "@constants/routes";

function PaidUserOnly({ children }: { children: JSX.Element }) {
  const { user } = useContext(AppContext);
  const payment = user?.payment;
  const location = useLocation();
  const plan = getPlanFromPayment(payment);

  return (
    <>
      {plan === PLAN.FREE && (
        <Navigate to={ROUTE_UPGRADE} state={{ from: location }} replace />
      )}
      {plan !== PLAN.FREE && children}
    </>
  );
}

export default PaidUserOnly;
