interface NumberInputProps {
  value?: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  [otherProps: string]: any;
}

function NumberInput({ value, onChange, ...otherProps }: NumberInputProps) {
  const showingValue = Math.floor(value || 0);
  return (
    <input
      type="number"
      onChange={onChange}
      value={showingValue}
      {...otherProps}
    />
  );
}

export default NumberInput;
