import { INTERNAL_SERVER_URL } from "@constants/api";
import { RawTemplate, Template } from "../type";
import { authInstance } from "@utils/firebase";

async function listTemplates() {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(`${INTERNAL_SERVER_URL}/template/list`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
  const result = await response.json();

  if (response.ok) {
    result.data = (result.data as RawTemplate[]).map((eachTemplate) => {
      try {
        eachTemplate.data = JSON.parse(eachTemplate.data);
      } catch (error) {}

      return eachTemplate as unknown as Template;
    });
    return result;
  } else {
    throw new Error(result.error);
  }
}

async function getTemplate(templateId: string): Promise<Template> {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(
    `${INTERNAL_SERVER_URL}/template/get?id=${templateId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  const result = await response.json();
  if (response.ok) {
    return result.data;
  } else {
    throw new Error(result.error);
  }
}

async function deleteTemplate(templateId: string) {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(
    `${INTERNAL_SERVER_URL}/template/delete?id=${templateId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  const result = await response.json();
  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

async function createTemplate({
  title,
  width,
  height,
}: {
  title: string;
  width: number;
  height: number;
}) {
  const idToken = await authInstance.currentUser?.getIdToken();
  const response = await fetch(`${INTERNAL_SERVER_URL}/template/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      title,
      width,
      height,
    }),
  });
  const result = await response.json();
  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

async function duplicateTemplate({ templateId }: { templateId: string }) {
  const idToken = await authInstance.currentUser?.getIdToken();
  const response = await fetch(`${INTERNAL_SERVER_URL}/template/duplicate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify({
      id: templateId,
    }),
  });

  const result = await response.json();
  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

async function updateTemplate(template: Partial<Template>) {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(`${INTERNAL_SERVER_URL}/template/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
    body: JSON.stringify(template),
  });

  const result = await response.json();
  if (response.ok) {
    try {
      const rawTemplate = result.data as RawTemplate;
      result.data.data = JSON.parse(rawTemplate.data);
    } catch (error) {}
    return result;
  } else {
    throw new Error(result.error);
  }
}

export {
  listTemplates,
  createTemplate,
  updateTemplate,
  duplicateTemplate,
  getTemplate,
  deleteTemplate,
};
