import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";
import NumberInput from "./shared/NumberInput";

const DEFAULT_WIDTH = 0;

function BorderRadius() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [width, setWidth] = useState(DEFAULT_WIDTH);

  // triggered by clicking layers
  useEffect(() => {
    const value =
      (selectedObject as fabric.Rect).get("rx") ||
      (selectedObject as fabric.Rect).get("ry") ||
      DEFAULT_WIDTH;

    setWidth(value);
  }, [selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.max(0, parseInt(event.target.value, 10));
    (selectedObject as fabric.Rect).set({
      rx: value,
      ry: value,
    });
    setWidth(value);
    canvas?.renderAll();
  }

  return (
    <NumberInput
      onChange={onChange}
      value={width}
      min={0}
      step={1}
      className="h-8 w-full rounded"
    />
  );
}

export default BorderRadius;
