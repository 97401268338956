import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function Opacity() {
  const { canvas, selectedObject, coreHandler } = useContext(AppContext);
  const [opacity, setOpacity] = useState(selectedObject?.opacity);

  // triggered by clicking layers
  useEffect(() => {
    setOpacity(selectedObject?.opacity);
  }, [selectedObject]);

  // triggered by fabric controls
  useEffect(() => {
    function onModified(event: any) {
      setOpacity(selectedObject?.opacity);
    }

    coreHandler?.eventHandler.on("object:modified", onModified);
    return () => {
      coreHandler?.eventHandler.off("object:modified", onModified);
    };
  }, [coreHandler, selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = Math.max(0, Math.min(parseFloat(event.target.value), 1));
    setOpacity(value);
    selectedObject?.set({ opacity: value });
    canvas?.renderAll();
  }

  return (
    <input
      type="number"
      onChange={onChange}
      value={opacity}
      min={0}
      max={1}
      step={0.1}
      className="h-8 w-full rounded"
    />
  );
}

export default Opacity;
