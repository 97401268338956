/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  DEFAULT_TEMPLATE_TITLE,
  DEFAULT_TEMPLATE_WIDTH,
  DEFAULT_TEMPLATE_HEIGHT,
  MAX_TEMPLATE_WIDTH,
  MAX_TEMPLATE_HEIGHT,
} from "@utils/constant";

import Templates, {
  TemplateItemInfo,
  TemplateItemCategories,
} from "./templates";

import TemplateItem from "./TemplateItem";

type TemplateInfo = {
  title: string;
  width: number;
  height: number;
};

function AddTemplateModal({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
}: {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (templateInfo: TemplateInfo) => void;
}) {
  const cancelButtonRef = useRef(null);
  const [openTab, setOpenTab] = useState(
    Object.keys(Templates)[0] as TemplateItemCategories
  );
  const [selectedTemplateItem, setSelectedTemplateItem] = useState("");
  const [title, setTitle] = useState(DEFAULT_TEMPLATE_TITLE);
  const [width, setWidth] = useState(DEFAULT_TEMPLATE_WIDTH);
  const [height, setHeight] = useState(DEFAULT_TEMPLATE_HEIGHT);

  function onSelectTemplateItem(data: TemplateItemInfo) {
    const { width, height } = data;
    setWidth(width);
    setHeight(height);
    setSelectedTemplateItem(data.label);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle">
              <div className="flex">
                <div className="w-3/4">
                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div>
                      <div className="text-center sm:text-left">
                        <Dialog.Title
                          as="h1"
                          className="mb-4 text-2xl font-bold leading-6 text-gray-900"
                        >
                          Create Template
                        </Dialog.Title>
                        <div>
                          <ul
                            className="mb-0 flex list-none flex-row flex-wrap pt-3 pb-4"
                            role="tablist"
                          >
                            {Object.keys(Templates).map((category) => {
                              return (
                                <li
                                  className="-mb-px mr-2 text-center last:mr-0"
                                  key={category}
                                >
                                  <a
                                    className={
                                      "block border-b py-3 text-sm font-bold uppercase leading-normal " +
                                      (openTab === category
                                        ? "border-b-blue-500 text-blue-500"
                                        : "border-b-white text-gray-500")
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setOpenTab(
                                        category as TemplateItemCategories
                                      );
                                    }}
                                    data-toggle="tab"
                                    href={`#link${category}`}
                                    role="tablist"
                                  >
                                    <i className="fas fa-cog mr-1 text-base"></i>{" "}
                                    {category}
                                  </a>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="flex">
                          <div className="flex h-96 flex-row flex-wrap overflow-auto">
                            {Templates[openTab].map((templateItemInfo) => {
                              return (
                                <TemplateItem
                                  selected={
                                    templateItemInfo.label ===
                                    selectedTemplateItem
                                  }
                                  data={templateItemInfo}
                                  onSelect={onSelectTemplateItem}
                                  key={templateItemInfo.label}
                                />
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-1/4 bg-slate-100">
                  <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="mt-2">
                      <div className="mb-4">
                        <label className="text-md mb-2 block font-bold text-gray-700">
                          Title
                        </label>
                        <input
                          className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                          type="text"
                          placeholder={DEFAULT_TEMPLATE_TITLE}
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="flex justify-between space-x-2">
                        <div className="mb-4">
                          <label className="text-md mb-2 block font-bold text-gray-700">
                            Width (px)
                          </label>
                          <input
                            className="focus:shadow-outline mb-2 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                            type="number"
                            placeholder="width"
                            value={width}
                            onChange={(e) => setWidth(parseInt(e.target.value))}
                          />
                          <div className="text-xs font-bold text-gray-400">
                            Max Width: {MAX_TEMPLATE_WIDTH} px
                          </div>
                        </div>
                        <div className="mb-4">
                          <label className="text-md mb-2 block font-bold text-gray-700">
                            Height (px)
                          </label>
                          <input
                            className="focus:shadow-outline mb-2 w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                            type="number"
                            placeholder="height"
                            value={height}
                            onChange={(e) =>
                              setHeight(parseInt(e.target.value))
                            }
                          />
                          <div className="text-xs font-bold text-gray-400">
                            Max Height: {MAX_TEMPLATE_HEIGHT} px
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    onSubmit({
                      width,
                      height,
                      title,
                    });
                  }}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    onCancel();
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AddTemplateModal;
