import { PhotoIcon } from "@heroicons/react/24/outline";

function NoRenderings() {
  return (
    <div className="text-center">
      <PhotoIcon className="mx-auto h-6 w-6 text-gray-500" aria-hidden="true" />
      <h3 className="mt-2 text-sm font-semibold text-gray-900">
        No Renderings
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        You can check our API docs or #NoCode solution to create one!
      </p>
    </div>
  );
}

export default NoRenderings;
