export const ESCAPE = "Escape";
export const DELETE = "Delete";
export const BACKSPACE = "Backspace";
export const ENTER = "Enter";
export const EQUAL = "Equal";
export const MINUS = "Minus";
export const KEY_A = "KeyA";
export const KEY_Q = "KeyQ";
export const KEY_W = "KeyW";
export const KEY_C = "KeyC";
export const KEY_V = "KeyV";
export const KEY_Z = "KeyZ";
export const KEY_Y = "KeyY";
export const KEY_O = "KeyO";
export const KEY_P = "KeyP";
export const KEY_R = "KeyR";
export const KEY_S = "KeyS";
export const KEY_T = "KeyT";
export const KEY_X = "KeyX";
export const KEY_0 = "Digit0";
export const KEY_EQUAL = "Equal";
export const KEY_MINUS = "Minus";
export const ARROW_UP = "ArrowUp";
export const ARROW_DOWN = "ArrowDown";
export const ARROW_LEFT = "ArrowLeft";
export const ARROW_RIGHT = "ArrowRight";
export const BRACKET_LEFT = "BracketLeft";
export const BRACKET_RIGHT = "BracketRight";
