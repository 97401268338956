import { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

const TRANSPARENT_BACKGROUND = `url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=")`;

type ColorType = string | { r: string; g: string; b: string; a: string };

function Color({
  color,
  onChange,
  size = "small",
}: {
  color: ColorType;
  onChange: (color: ColorType) => void;
  size?: "small" | "large";
}) {
  const [internalColor, setInternalColor] = useState(color);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  useEffect(() => {
    setInternalColor(color);
  }, [color]);

  function handleClick() {
    setDisplayColorPicker(!displayColorPicker);
  }

  function handleClose() {
    setDisplayColorPicker(false);
  }

  function handleChange(newColor) {
    if (typeof color === "string") {
      setInternalColor(newColor.hex);
      onChange(newColor.hex);
    } else {
      setInternalColor(newColor.rgb);
      onChange(newColor.rgb);
    }
  }

  return (
    <div className="relative flex">
      <div
        className="inline-block cursor-pointer rounded-sm bg-white py-[5px] px-1 ring-1 ring-gray-500"
        onClick={handleClick}
      >
        <div
          className={`h-5 rounded-sm ring-1 ring-gray-500 ${
            size === "large" ? "w-10" : "w-6"
          }`}
          style={
            typeof internalColor === "string"
              ? internalColor === "transparent"
                ? {
                    background: `${TRANSPARENT_BACKGROUND}`,
                  }
                : {
                    background: `${internalColor}`,
                  }
              : {
                  background: `rgba(${internalColor.r}, ${internalColor.g}, ${internalColor.b}, ${internalColor.a})`,
                }
          }
        />
      </div>
      {displayColorPicker ? (
        <div className="absolute -top-80 z-50">
          <div
            className="fixed top-0 left-0 bottom-0 right-0"
            onClick={handleClose}
          />
          <SketchPicker
            color={internalColor}
            onChange={handleChange}
            presetColors={[
              "#D0021B",
              "#F5A623",
              "#F8E71C",
              "#8B572A",
              "#7ED321",
              "#417505",
              "#BD10E0",
              "#9013FE",
              "#4A90E2",
              "#50E3C2",
              "#B8E986",
              "#000000",
              "#4A4A4A",
              "#9B9B9B",
              "#FFFFFF",
              "transparent",
            ]}
          />
        </div>
      ) : null}
    </div>
  );
}

export default Color;
