import { APIResponse } from "./type";
import { INTERNAL_SERVER_URL } from "@constants/api";
import { authInstance } from "@utils/firebase";

interface Overview {
  totalTemplateCount: number;
  totalRenderingCount: number;
  monthlyRenderingCount: number;
}

async function getOverview(): Promise<APIResponse<Overview>> {
  const idToken = await authInstance.currentUser?.getIdToken();
  const response = await fetch(`${INTERNAL_SERVER_URL}/overview/get`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${idToken}`,
    },
  });
  const result = await response.json();
  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

export { getOverview };
