import React, { useContext, useEffect, useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../../context/AppContext";

const DEFAULT_CONTENT = "https://copicake.com";

function Qrcode() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [content, setContent] = useState(
    (selectedObject as fabric.Qrcode).get("content" as keyof fabric.Qrcode) ||
      DEFAULT_CONTENT
  );

  // triggered by clicking layers
  useEffect(() => {
    setContent(
      (selectedObject as fabric.Qrcode).get("content" as keyof fabric.Qrcode) ||
        DEFAULT_CONTENT
    );
  }, [selectedObject]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.target.value;
    if (value !== content) {
      setContent(value);
      (selectedObject as fabric.Qrcode).set(
        "content" as keyof fabric.Qrcode,
        value || DEFAULT_CONTENT
      );
      canvas?.renderAll();
    }
  }

  return (
    <input
      type="text"
      onChange={onChange}
      value={content}
      className="h-8 w-full rounded"
    />
  );
}

export default Qrcode;
