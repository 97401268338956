import { duplicateTemplate, getTemplate } from "@apis/template";
import { Template } from "@base/type";
import Header from "@components/Header";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "@components/shared/Loader";
import Snapshot from "@components/shared/Snapshot";
import { AppContext } from "@base/context/AppContext";
import { ROUTE_DASHBOARD } from "@constants/routes";

function Peek() {
  const { user } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isDuplicating, setIsDuplicating] = useState(false);
  const [template, setTemplate] = useState<Template | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  function onClickToDuplicate() {
    if (isLoading || isDuplicating) {
      return;
    }
    if (!user) {
      toast.error("Please login to duplicate template");
      return;
    }

    setIsDuplicating(true);

    const templateId = template!.id;
    toast.promise(duplicateTemplate({ templateId }), {
      pending: "Duplicating template...",
      success: {
        render({ data }: { data: { data: Template } }) {
          setIsDuplicating(false);
          setTimeout(() => {
            navigate(ROUTE_DASHBOARD);
          }, 1500);
          return "Template created!";
        },
      },
      error: {
        render({ data }) {
          setIsDuplicating(false);
          return (data as Error).message;
        },
      },
    });
  }

  useEffect(() => {
    async function initialize() {
      const splits = location.pathname.split("/");
      const templateId = splits[splits.length - 1];

      try {
        const template = await getTemplate(templateId);
        setTemplate(template);
      } catch (error) {
        toast.error((error as Error).message);
      } finally {
        setIsLoading(false);
      }
    }

    initialize();
  }, [location.pathname, template]);

  return (
    <div className="h-full">
      <Header />
      <div className="h-full md:h-[calc(100%-73px-81px)]">
        <div className="flex h-full w-full items-center justify-center">
          {isLoading && <Loader className="text-gray-500" />}
          {!isLoading && !template && (
            <div className="text-black">
              Failed to peek designated template, please try again!
            </div>
          )}
          {!isLoading && template && (
            <>
              <div className="relative flex h-full w-full flex-col items-center justify-center bg-gray-100">
                <div className="h-2/3 w-2/3">
                  <Snapshot template={template} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="border-t p-5">
        <div className="flex justify-center">
          <div className="flex">
            <button
              className="focus:shadow-outline rounded bg-indigo-500 py-2 px-4 font-bold text-white hover:bg-indigo-600 focus:outline-none"
              type="button"
              onClick={onClickToDuplicate}
              disabled={isLoading || isDuplicating || !template}
            >
              <span className="mr-1">Make a copy to your dashboard</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Peek;
