import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import Height from "./Properties/Height";
import Name from "./Properties/Name";
import Width from "./Properties/Width";
import Fill from "./Properties/Fill";
import Angle from "./Properties/Angle";
import Left from "./Properties/Left";
import Top from "./Properties/Top";
import PropertiesEmpty from "./PropertiesEmpty";
import ClipPath from "./Properties/ClipPath";
import Opacity from "./Properties/Opacity";
import PropertyLabel from "./Properties/shared/PropertyLabel";
import TextBackgroundColor from "./Properties/TextBackgroundColor";
import Font from "./Properties/Font";
import Shadow from "./Properties/Shadow";
import Stroke from "./Properties/Stroke";
import BorderRadius from "./Properties/BorderRadius";
import Qrcode from "./Properties/Qrcode";

function doesExist(value: unknown): boolean {
  return value !== undefined && value !== null;
}

function Properties() {
  const { selectedObject } = useContext(AppContext);
  return (
    <div className="h-full overflow-auto border-l p-5">
      {!selectedObject && (
        <div className="flex h-full items-center justify-center">
          <PropertiesEmpty />
        </div>
      )}
      {selectedObject && (
        <div className="space-y-3">
          {doesExist(selectedObject?.get("name")) && (
            <div>
              <PropertyLabel>Name</PropertyLabel>
              <Name />
            </div>
          )}
          {doesExist(selectedObject?.get("width")) &&
            doesExist(selectedObject?.get("height")) && (
              <div className="flex flex-row space-x-2">
                <div>
                  <PropertyLabel>Width</PropertyLabel>
                  <Width />
                </div>
                <div>
                  <PropertyLabel>Height</PropertyLabel>
                  <Height />
                </div>
              </div>
            )}
          <div className="flex flex-row space-x-2">
            <div className="w-1/2">
              {doesExist(selectedObject?.get("left")) && (
                <div>
                  <PropertyLabel>Left</PropertyLabel>
                  <Left />
                </div>
              )}
            </div>
            <div className="w-1/2">
              {doesExist(selectedObject?.get("top")) && (
                <div>
                  <PropertyLabel>Top</PropertyLabel>
                  <Top />
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row space-x-2">
            <div className="w-1/2">
              {doesExist(selectedObject?.get("angle")) && (
                <div>
                  <PropertyLabel>Angle</PropertyLabel>
                  <Angle />
                </div>
              )}
            </div>
            <div className="w-1/2">
              {doesExist(selectedObject?.get("opacity")) && (
                <div>
                  <PropertyLabel>Opacity</PropertyLabel>
                  <Opacity />
                </div>
              )}
            </div>
          </div>
          {doesExist(selectedObject?.get("fill")) && (
            <div>
              <PropertyLabel>Fill</PropertyLabel>
              <Fill />
            </div>
          )}
          {selectedObject.type === "qrcode" && (
            <div>
              <PropertyLabel>Content</PropertyLabel>
              <Qrcode />
            </div>
          )}
          {selectedObject.type === "textbox" && (
            <div>
              <Font />
            </div>
          )}
          {selectedObject.type === "textbox" && (
            <div>
              <PropertyLabel>Text Background Color</PropertyLabel>
              <TextBackgroundColor />
            </div>
          )}
          {selectedObject.type === "rect" && (
            <div>
              <PropertyLabel>Border Radius</PropertyLabel>
              <BorderRadius />
            </div>
          )}
          <div>
            <PropertyLabel>Stroke</PropertyLabel>
            <Stroke />
          </div>
          {/* the default shadow value is null */}
          <div>
            <PropertyLabel>Shadow</PropertyLabel>
            <Shadow />
          </div>
          {selectedObject.type === "image" && (
            <div>
              <h2>Image ClipPaths</h2>
              <ClipPath />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Properties;
