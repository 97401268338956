import { Provider } from "@rollbar/react";
import React from "react";
import Rollbar from "rollbar";
import { ROLLBAR_TOKEN } from "@utils/constant";
import { isLocalhost, isProduction } from "@utils/env";

export const RollbarProvider: React.FC = ({ children }) => {
  const rollbarConfig: Rollbar.Configuration = {
    accessToken: ROLLBAR_TOKEN,
    environment: isProduction ? "production" : "development",
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      client: {
        javascript: {
          code_version: "1.0.0",
          source_map_enabled: true,
        },
      },
    },
    enabled: !isLocalhost,
  };

  return <Provider config={rollbarConfig}>{children}</Provider>;
};
