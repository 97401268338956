import { fabric } from "@utils/fabric";
import { Circle } from "react-bootstrap-icons";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function ClipPath() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [clipPath, setClipPath] = useState(
    (selectedObject as fabric.Image)?.clipPath
  );

  // triggered by clicking layers
  useEffect(() => {
    setClipPath((selectedObject as fabric.Image)?.clipPath);
  }, [selectedObject]);

  function onClick() {
    const width = (selectedObject as fabric.Image)?.width || 0;
    const height = (selectedObject as fabric.Image)?.height || 0;
    const radius = Math.min(width, height) * 0.5;
    const newClipPath =
      clipPath === undefined
        ? new fabric.Circle({
            radius,
            originX: "center",
            originY: "center",
          })
        : undefined;
    setClipPath(newClipPath);
    (selectedObject as fabric.Image)?.set({ clipPath: newClipPath });
    canvas?.renderAll();
  }

  return (
    <div>
      <button
        className={`rounded border border-gray-500 p-2 ${
          clipPath === undefined ? "" : "bg-blue-400 text-white"
        }`}
        onClick={onClick}
      >
        <Circle size={16} />
      </button>
    </div>
  );
}

export default ClipPath;
