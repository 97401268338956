import { fabric, getFontFamiliesFromFabricObjects } from "@utils/fabric";
import { useState, useEffect } from "react";
import { Template } from "../../type";
import Loader from "../shared/Loader";
import FontHandler from "@base/handlers/FontHandler";

type Props = {
  template: Template;
};

function Snapshot({ template }: Props) {
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const canvasId = `canvas-${template.id}`;

  useEffect(() => {
    // In this scenario, we don't have coreHandler,
    // so we need to pass null as the first argument
    const fontHandler = new FontHandler(null as any);
    const canvas = new fabric.Canvas(canvasId, {
      width: template.width,
      height: template.height,
    });

    const fontFamilies = getFontFamiliesFromFabricObjects(
      template.data.objects
    );

    fontHandler.loadFont(fontFamilies).finally(() => {
      canvas.loadFromJSON(template.data, () => {
        canvas.renderAll();
        setThumbnailUrl(
          canvas.toDataURL({
            multiplier: window.devicePixelRatio || 1,
          })
        );
      });
    });
  }, [canvasId, template.data, template.height, template.width]);

  return (
    <div className="relative h-full w-full">
      {thumbnailUrl ? (
        <img
          src={thumbnailUrl}
          alt={template.title}
          className="mx-auto h-full object-contain"
        ></img>
      ) : (
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        </div>
      )}
      <div className="hidden">
        <canvas id={canvasId}></canvas>
      </div>
    </div>
  );
}

export default Snapshot;
