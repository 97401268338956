import { Fragment, useContext, useEffect, useRef, useState } from "react";
import {
  CheckIcon,
  XMarkIcon,
  InformationCircleIcon,
  StarIcon,
  ArrowRightIcon,
} from "@heroicons/react/20/solid";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "@context/AuthContext";
import { AppContext } from "@context/AppContext";
import { fireConfetti } from "@utils/confetti";
import { IPlan, PLAN, getPlanFromPayment } from "@utils/payment";
import { INTEGRATION_MAKE_ARTICLE_URL } from "@utils/constant";
import { updatePlan } from "@apis/plan";

type Props = {
  open: boolean;
  onClose?: () => void;
};

function UpdatePlanModal({ open = false, onClose = () => {} }: Props) {
  const { user } = useContext(AppContext);
  const userAuth = useContext(AuthContext);
  const cancelButtonRef = useRef(null);
  const [category, setCategory] = useState("annually");
  const [toPlan, setToPlan] = useState<IPlan | null>(null);
  const [isConfirmToUpdateModalShowing, setIsConfirmToUpdateModalShowing] =
    useState(false);
  const navigate = useNavigate();
  const userPlan = getPlanFromPayment(user?.payment);

  useEffect(() => {
    function onPaddleEvent(event: CustomEvent) {
      const data = event.detail;
      if (data.event === "Checkout.Complete") {
        fireConfetti();
        toast.success("Thanks for your purchase! Refreshing in seconds...");
        setTimeout(() => {
          navigate(0);
        }, 1500);
      } else if (data.event === "Checkout.Close") {
        toast.info("Checkout has been cancelled.");
      }
    }

    window.addEventListener("paddleEvent", onPaddleEvent);
    return () => {
      window.removeEventListener("paddleEvent", onPaddleEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function doUpdatePlan(planId: string) {
    try {
      await updatePlan(planId);
      toast.success(
        "Your plan has been updated successfully! Refreshing in seconds..."
      );
      setTimeout(() => {
        navigate(0);
      }, 1500);
    } catch (error) {
      toast.error(
        "Something went wrong when updating plan. Please try again later."
      );
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-7xl sm:align-middle">
              <div className="">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <div className="container mx-auto px-5 py-6">
                        <div className="mb-8 flex w-full flex-col text-center">
                          <div className="mx-auto flex overflow-hidden rounded ring-1 ring-green-500">
                            <button
                              className={`flex items-center py-1 px-4 ${
                                category === "monthly" &&
                                "bg-green-500 text-white focus:outline-none"
                              }`}
                              onClick={() => {
                                setCategory("monthly");
                              }}
                            >
                              {category === "monthly" && (
                                <CheckIcon className="mx-1 inline h-4 w-4" />
                              )}
                              Monthly
                            </button>
                            <button
                              className={`flex items-center py-1 px-4 focus:outline-none ${
                                category === "annually" &&
                                "bg-green-500 text-white focus:outline-none"
                              }`}
                              onClick={() => {
                                setCategory("annually");
                              }}
                            >
                              {category === "annually" && (
                                <CheckIcon className="mx-1 inline h-4 w-4" />
                              )}
                              Annually (20% off)
                            </button>
                          </div>
                        </div>
                        <div className="-m-4 flex flex-wrap">
                          {Object.keys(PLAN).map((plan, index) => {
                            const isPopular = PLAN[plan].POPULAR;
                            const currentPlanObject = PLAN[plan];
                            const selectedPlanId =
                              category === "monthly"
                                ? currentPlanObject.MONTHLY_PRODUCT_ID!
                                : currentPlanObject.YEARLY_PRODUCT_ID!;

                            const isUserCurrentPlan =
                              selectedPlanId ===
                              user?.payment?.subscription_plan_id;
                            const isFreePlan = plan === PLAN.FREE.TITLE;
                            const isClickable =
                              !isUserCurrentPlan && !isFreePlan;

                            return (
                              <div
                                className="w-full p-4 md:w-1/2 xl:w-1/4"
                                key={index}
                              >
                                <div
                                  className={`relative flex h-full flex-col overflow-hidden rounded-lg p-6 ring-1 ${
                                    isPopular
                                      ? "ring-green-500"
                                      : "ring-gray-300"
                                  }`}
                                >
                                  {isPopular && (
                                    <span className="absolute right-0 top-0 rounded-bl bg-green-500 px-3 py-1 text-xs tracking-widest text-white">
                                      POPULAR
                                    </span>
                                  )}
                                  <h2 className="text-lg font-semibold leading-8 text-gray-900">
                                    {PLAN[plan].TITLE}
                                  </h2>
                                  <h1 className="my-6 border-gray-200 text-5xl font-bold leading-none text-gray-900">
                                    {category === "monthly" && (
                                      <>
                                        <span>${PLAN[plan].PRICE_MONTHLY}</span>
                                        <span className="ml-1 text-lg font-normal text-gray-500">
                                          / month
                                        </span>
                                      </>
                                    )}
                                    {category === "annually" && (
                                      <>
                                        <span>${PLAN[plan].PRICE_YEARLY}</span>
                                        <span className="ml-1 text-lg font-normal text-gray-500">
                                          / month
                                        </span>
                                      </>
                                    )}
                                  </h1>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    <CheckIcon
                                      className="mr-2 h-5 w-5 flex-none text-green-500"
                                      aria-hidden="true"
                                    />
                                    <span className="mr-1 font-bold">
                                      {PLAN[plan].TEMPLATE_LIMIT}
                                    </span>
                                    <span className="text-sm leading-6 text-gray-600">
                                      {" "}
                                      templates
                                    </span>
                                  </p>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    <CheckIcon
                                      className="mr-2 h-5 w-5 flex-none text-green-500"
                                      aria-hidden="true"
                                    />
                                    <span className="mr-1 font-bold">
                                      {PLAN[plan].RENDERING_LIMIT}
                                    </span>
                                    <span className="text-sm leading-6 text-gray-600">
                                      {" "}
                                      renderings / month
                                    </span>
                                  </p>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    <CheckIcon
                                      className="mr-2 h-5 w-5 flex-none text-green-500"
                                      aria-hidden="true"
                                    />
                                    <span className="text-sm leading-6 text-gray-600">
                                      REST API Integration
                                    </span>
                                  </p>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    <CheckIcon
                                      className="mr-2 h-5 w-5 flex-none text-green-500"
                                      aria-hidden="true"
                                    />
                                    <span className="text-sm leading-6 text-gray-600">
                                      Easy-to-integrate SDKs
                                    </span>
                                  </p>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    <CheckIcon
                                      className="mr-2 h-5 w-5 flex-none text-green-500"
                                      aria-hidden="true"
                                    />
                                    <a
                                      href={INTEGRATION_MAKE_ARTICLE_URL}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span className="text-sm leading-6 text-gray-600 underline">
                                        #NoCode Integration
                                      </span>
                                    </a>
                                    <sup className="mx-1 text-xs font-bold text-red-400">
                                      (NEW)
                                    </sup>
                                  </p>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    {plan === PLAN.FREE.TITLE && (
                                      <XMarkIcon
                                        className="mr-2 h-5 w-5 flex-none text-gray-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {plan !== PLAN.FREE.TITLE && (
                                      <CheckIcon
                                        className="mr-2 h-5 w-5 flex-none text-green-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <span className="text-sm leading-6 text-gray-600">
                                      Rendering History
                                    </span>
                                  </p>
                                  <p className="mb-2 flex items-center text-gray-600">
                                    {plan === PLAN.FREE.TITLE && (
                                      <XMarkIcon
                                        className="mr-2 h-5 w-5 flex-none text-gray-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    {plan !== PLAN.FREE.TITLE && (
                                      <CheckIcon
                                        className="mr-2 h-5 w-5 flex-none text-green-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <span className="text-sm leading-6 text-gray-600">
                                      Priority Support
                                    </span>
                                  </p>
                                  {plan !== PLAN.FREE.TITLE && (
                                    <div>
                                      <div className="relative flex items-center py-2">
                                        <div className="flex-grow border-t border-dashed border-gray-400"></div>
                                        <span className="mx-3 flex-shrink text-sm text-gray-400">
                                          Coming Soon
                                        </span>
                                        <div className="flex-grow border-t border-dashed border-gray-400"></div>
                                      </div>
                                      <div className="mb-2 flex items-center text-gray-600">
                                        <CheckIcon
                                          className="mr-2 h-5 w-5 flex-none text-green-500"
                                          aria-hidden="true"
                                        />
                                        <span className="text-sm leading-6 text-gray-600">
                                          Template Gallery
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                  <p className="mb-4"></p>
                                  <button
                                    className={
                                      "mt-auto flex w-full items-center justify-between rounded bg-white py-2 px-4 text-green-500 ring-1 ring-green-500 hover:bg-gray-50 focus:outline-none"
                                    }
                                    onClick={() => {
                                      if (!isClickable) {
                                        return;
                                      }

                                      // for FREE -> other plans, we need to use paddle Checkout
                                      if (userPlan === PLAN.FREE) {
                                        (window as any).Paddle.Checkout.open({
                                          product: selectedPlanId,
                                          email: userAuth?.email,
                                          passthrough: JSON.stringify({
                                            user_id: user?.id,
                                          }),
                                        });
                                      }
                                      // for plan A <-> plan B, we need to use internal API
                                      else {
                                        setToPlan(currentPlanObject);
                                        setIsConfirmToUpdateModalShowing(true);
                                      }
                                    }}
                                  >
                                    <span>
                                      {isFreePlan
                                        ? "Start From here"
                                        : isUserCurrentPlan
                                        ? "You are here"
                                        : "Change Plan"}
                                    </span>
                                    {isFreePlan ? (
                                      <></>
                                    ) : isUserCurrentPlan ? (
                                      <StarIcon
                                        className="h-5 w-5 text-yellow-400"
                                        aria-hidden="true"
                                      />
                                    ) : (
                                      <ArrowRightIcon
                                        className="h-5 w-5 text-green-500"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                      onClose();
                    }}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
              <div
                className={
                  isConfirmToUpdateModalShowing
                    ? "fixed inset-0 z-50 flex items-center justify-center overflow-y-auto bg-black bg-opacity-40"
                    : "hidden"
                }
              >
                <div className="mx-4 w-full rounded-md bg-white md:w-1/2">
                  <div className="flex min-h-[320px] flex-col items-center justify-evenly p-4">
                    <div className="p-4 text-center">
                      Change from
                      <span className="px-1 text-lg font-bold">
                        {userPlan.TITLE}
                      </span>
                      plan to
                      <span className="px-1 text-lg font-bold">
                        {toPlan?.TITLE} ({category})
                      </span>
                      plan
                    </div>
                    <div className="px-4 text-left">
                      <div className="rounded-md bg-blue-50 p-4">
                        <div className="flex">
                          <div className="flex-shrink-0">
                            <InformationCircleIcon
                              className="h-5 w-5 text-blue-400"
                              aria-hidden="true"
                            />
                          </div>
                          <div className="ml-3">
                            <h3 className="text-sm font-bold text-blue-800">
                              Notice
                            </h3>
                            <ul className="p mt-3 list-disc space-y-1 text-sm text-blue-700">
                              <li>The change will take effect immediately</li>
                              <li>
                                If you upgrade to the higher plan, you will be
                                charged with the difference immediately
                              </li>
                              <li>
                                If you downgrade to the lower plan, the max
                                rendering count and creatable templates will be
                                reduced
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="justify-end rounded-b-md bg-gray-50 px-4 py-3 sm:flex sm:flex-row sm:px-6">
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setIsConfirmToUpdateModalShowing(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded border border-green-500 bg-white px-4 py-2 text-base font-medium text-green-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                      onClick={() => {
                        setIsConfirmToUpdateModalShowing(false);

                        const planId =
                          category === "monthly"
                            ? toPlan?.MONTHLY_PRODUCT_ID
                            : toPlan?.YEARLY_PRODUCT_ID;
                        if (planId) {
                          doUpdatePlan(planId);
                        }
                      }}
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default UpdatePlanModal;
