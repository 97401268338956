import React, { useContext } from "react";
import {
  TriangleFill,
  CircleFill,
  Fonts,
  SquareFill,
  Image,
  DashLg,
} from "react-bootstrap-icons";
import { BsQrCodeScan } from "react-icons/bs";
import { AppContext } from "../context/AppContext";
import { fabric } from "@utils/fabric";

interface IProps {
  object: fabric.Object;
  selected: boolean;
  isLayerOpened: boolean;
}

const iconMapping = {
  triangle: TriangleFill,
  circle: CircleFill,
  rect: SquareFill,
  image: Image,
  textbox: Fonts,
  line: DashLg,
  qrcode: BsQrCodeScan,
  default: CircleFill,
};

const nameMapping = {
  triangle: "Triangle",
  circle: "Circle",
  rect: "Rect",
  image: "Image",
  textbox: "Text",
  line: "Line",
  qrcode: "QRCode",
  default: "Unknown",
};

const ICON_SIZE = 16;

function LayerItem({ object, selected, isLayerOpened }: IProps) {
  const { coreHandler } = useContext(AppContext);

  const type = object?.type?.toLowerCase() as keyof typeof iconMapping;
  const icon =
    typeof iconMapping[type] !== "undefined"
      ? React.createElement(iconMapping[type], { size: ICON_SIZE })
      : React.createElement(iconMapping.default, { size: ICON_SIZE });

  function getLayerName(): string {
    return object?.name || nameMapping[type];
  }

  return (
    <div
      data-tip={getLayerName()}
      data-place="right"
      className={`flex cursor-pointer items-center space-x-2 p-2 hover:bg-gray-300 ${
        isLayerOpened ? "justify-start" : "justify-center"
      } ${selected ? "font-bold text-blue-500" : ""}`}
      onClick={() => {
        coreHandler?.canvas.setActiveObject(object);
        coreHandler?.canvas.renderAll();
      }}
    >
      <div className={`py-1 ${isLayerOpened ? "pl-2 pr-1" : ""}`}>{icon}</div>
      {isLayerOpened && (
        <div className="truncate text-sm">{getLayerName()}</div>
      )}
    </div>
  );
}

export default LayerItem;
