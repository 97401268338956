import { INTERNAL_SERVER_URL } from "@constants/api";
import { authInstance } from "@utils/firebase";

export function isProxiedURL(url: string) {
  const regex = new RegExp(`^${INTERNAL_SERVER_URL}/proxy-image?=`);
  if (url.match(regex)) {
    return true;
  }
  return false;
}

export function getProxiedURL(url: string) {
  return `${INTERNAL_SERVER_URL}/proxy-image?url=${url}`;
}

export async function proxyImage(url: string) {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(
    `${INTERNAL_SERVER_URL}/proxy-image?url=${url}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  );

  if (response.ok) {
    const result = await response.blob();
    const url = URL.createObjectURL(result);
    return url;
  } else {
    const result = await response.json();
    throw new Error(result.error);
  }
}
