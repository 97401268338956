import { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { FirebaseUser } from "../type";
import { authInstance } from "@utils/firebase";

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<FirebaseUser | null | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged((firebaseUser) => {
      setUser(firebaseUser);
    });

    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};
