import { useContext, useEffect, useState } from "react";
import {
  ChevronUp,
  ChevronDown,
  ChevronDoubleDown,
  ChevronDoubleUp,
  Trash,
  TriangleFill,
  CircleFill,
  Fonts,
  SquareFill,
  Image,
  Gear,
  Brush,
  ArrowCounterclockwise,
  ArrowClockwise,
} from "react-bootstrap-icons";
import {
  BiObjectsHorizontalLeft,
  BiObjectsHorizontalCenter,
  BiObjectsHorizontalRight,
  BiObjectsVerticalTop,
  BiObjectsVerticalCenter,
  BiObjectsVerticalBottom,
} from "react-icons/bi";
import { BsQrCodeScan } from "react-icons/bs";
import { AppContext } from "../context/AppContext";
import { createDefaultObject, ObjectType } from "@utils/utils";

const ICON_SIZE = 16;

type Props = {
  isSaving: boolean;
  toShowSaveTemplateButton: boolean;
  onSaveTemplate: () => void;
  onTemplateSettingsClick: () => void;
  onRenderClick: () => void;
  onAddImageClick: () => void;
  onShortcutClick: () => void;
};

function Toolbars({
  isSaving,
  toShowSaveTemplateButton,
  onSaveTemplate = () => {},
  onTemplateSettingsClick = () => {},
  onRenderClick = () => {},
  onAddImageClick = () => {},
  onShortcutClick = () => {},
}: Props) {
  const { coreHandler, selectedObject } = useContext(AppContext);
  const [isAnyObjectSelected, setIsAnyObjectSelected] = useState(false);
  const [isRedoDisabled, setIsRedoDisabled] = useState(true);
  const [isUndoDisabled, setIsUndoDisabled] = useState(true);

  function addAndRenderObject(type: ObjectType) {
    const object = createDefaultObject(type);
    coreHandler?.add(object);
  }

  useEffect(() => {
    setIsAnyObjectSelected(!!selectedObject);
  }, [selectedObject]);

  useEffect(() => {
    coreHandler?.transactionHandler.on("change", () => {
      setIsRedoDisabled(coreHandler.transactionHandler.redos.length === 0);
      setIsUndoDisabled(coreHandler.transactionHandler.undos.length === 0);
    });
  }, [coreHandler?.transactionHandler]);

  return (
    <div className="flex items-center justify-between border-b bg-gray-100 px-5 py-2">
      <div className="flex space-x-2">
        <button
          className="rounded p-2 hover:bg-gray-300"
          data-tip="QRcode"
          onClick={() => {
            addAndRenderObject("qrcode");
          }}
        >
          <BsQrCodeScan size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300"
          data-tip="Triangle (a)"
          onClick={() => {
            addAndRenderObject("triangle");
          }}
        >
          <TriangleFill size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300"
          data-tip="Circle (c)"
          onClick={() => {
            addAndRenderObject("circle");
          }}
        >
          <CircleFill size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300"
          data-tip="Rect (r)"
          onClick={() => {
            addAndRenderObject("rect");
          }}
        >
          <SquareFill size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300"
          data-tip="Text (t)"
          onClick={() => {
            addAndRenderObject("text");
          }}
        >
          <Fonts size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300"
          data-tip="Image"
          onClick={() => {
            onAddImageClick();
          }}
        >
          <Image size={ICON_SIZE} />
        </button>
        <div className="w-px bg-slate-400"></div>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Bring Forward ( [ )"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.bringForward();
          }}
        >
          <ChevronUp size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Send Backward ( ] )"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.sendBackward();
          }}
        >
          <ChevronDown size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Bring to front"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.bringToFront();
          }}
        >
          <ChevronDoubleUp size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Send to back"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.sendToBack();
          }}
        >
          <ChevronDoubleDown size={ICON_SIZE} />
        </button>
        <div className="w-px bg-slate-400"></div>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Align to Left Edge"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.alignToLeftEdge();
          }}
        >
          <BiObjectsHorizontalLeft size={ICON_SIZE} type="logo" />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Align to Center Horizontal"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.alignToCenterHorizontal();
          }}
        >
          <BiObjectsHorizontalCenter size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Align to Right Edge"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.alignToRightEdge();
          }}
        >
          <BiObjectsHorizontalRight size={ICON_SIZE} />
        </button>
        <div className="w-px bg-slate-400"></div>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Align to Top Edge"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.alignToTopEdge();
          }}
        >
          <BiObjectsVerticalTop size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Align to Center Vertical"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.alignToCenterVertical();
          }}
        >
          <BiObjectsVerticalCenter size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Align to Bottom Edge"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.alignToBottomEdge();
          }}
        >
          <BiObjectsVerticalBottom size={ICON_SIZE} />
        </button>

        <div className="w-px bg-slate-400"></div>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Undo (meta + z)"
          disabled={isUndoDisabled}
          onClick={() => {
            coreHandler?.transactionHandler.undo();
          }}
        >
          <ArrowCounterclockwise size={ICON_SIZE} />
        </button>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Redo (shift + meta + z)"
          disabled={isRedoDisabled}
          onClick={() => {
            coreHandler?.transactionHandler.redo();
          }}
        >
          <ArrowClockwise size={ICON_SIZE} />
        </button>
        <div className="w-px bg-slate-400"></div>
        <button
          className="rounded p-2 hover:bg-gray-300 disabled:text-slate-300 disabled:hover:bg-gray-100"
          data-tip="Remove [backspace]"
          disabled={!isAnyObjectSelected}
          onClick={() => {
            coreHandler?.remove();
          }}
        >
          <Trash size={ICON_SIZE} />
        </button>
      </div>
      <div className="flex space-x-2">
        {toShowSaveTemplateButton && (
          <button
            className="flex items-center justify-center rounded bg-orange-100 p-2 hover:bg-orange-200"
            onClick={onSaveTemplate}
          >
            <span className="text-xs">Autosaving ...</span>
          </button>
        )}
        <button
          className="flex items-center justify-center rounded p-2 hover:bg-gray-300"
          data-tip="Render"
          onClick={onRenderClick}
        >
          <Brush size={ICON_SIZE} />
        </button>
        <button
          className="flex items-center justify-center rounded p-2 hover:bg-gray-300"
          data-tip="Template Settings"
          onClick={onTemplateSettingsClick}
        >
          <Gear size={ICON_SIZE} />
        </button>
      </div>
    </div>
  );
}

export default Toolbars;
