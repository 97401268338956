import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { authInstance, firebaseAuth } from "@utils/firebase";
import { createUser } from "@apis/user";
import Header from "@components/Header";
import { ROUTE_LOGIN, ROUTE_DASHBOARD } from "@constants/routes";

function Register() {
  const [isOperating, setIsOperating] = useState(false);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
  }

  const signUp = async () => {
    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;
    const passwordConfirm = passwordConfirmRef.current?.value;

    if (isOperating) {
      return;
    }

    if (!email) {
      toast.error("Email is required");
      return;
    }

    if (!password || !passwordConfirm) {
      toast.error("Password is required");
      return;
    }

    if (password !== passwordConfirm) {
      toast.error("Passwords do not match");
      return;
    }

    const toastId = toast.loading("Creating account ...");
    setIsOperating(true);

    try {
      const credential = await firebaseAuth.createUserWithEmailAndPassword(
        authInstance,
        email,
        password
      );

      await credential.user.getIdToken();

      // TODO: block UI interaction here, add hints
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { data: user } = await createUser();

      toast.update(toastId, {
        render: "Account created!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
      });
      navigate(ROUTE_DASHBOARD, { replace: true });
      window.gtag("event", "signup_button_click", {
        send_to: "G-27400960CJ",
      });
    } catch (error) {
      toast.update(toastId, {
        render: (error as Error).message,
        type: "error",
        isLoading: false,
        autoClose: 2000,
      });
    } finally {
      setIsOperating(false);
    }
  };

  return (
    <div className="register-page h-full">
      <Header />
      <div className="h-[calc(100%-73px)]">
        <div className="flex h-full w-full items-center justify-center">
          <div className="w-full max-w-lg">
            <form
              className="mb-4 rounded bg-white px-8 pt-6 pb-8 drop-shadow-md"
              onSubmit={onSubmit}
            >
              <div className="mb-4">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="email"
                >
                  Email
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                  id="email"
                  type="email"
                  placeholder="your_email@google.com"
                  ref={emailRef}
                />
              </div>
              <div className="mb-6">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="password"
                >
                  Password
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                  id="password"
                  type="password"
                  placeholder="******************"
                  ref={passwordRef}
                />
              </div>
              <div className="mb-6">
                <label
                  className="mb-2 block text-sm font-bold text-gray-700"
                  htmlFor="password-confirm"
                >
                  Confirm Password
                </label>
                <input
                  className="focus:shadow-outline w-full appearance-none rounded border py-2 px-3 leading-tight text-gray-700 shadow focus:outline-none"
                  id="password-confirm"
                  type="password"
                  placeholder="******************"
                  ref={passwordConfirmRef}
                />
              </div>
              <div className="flex items-center justify-between">
                <button
                  className="focus:shadow-outline rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none"
                  type="submit"
                  onClick={() => {
                    signUp();
                  }}
                >
                  Sign Up
                </button>
                <button
                  className="text-md inline-block align-baseline font-bold text-blue-500 hover:text-blue-800"
                  onClick={() => {
                    navigate(ROUTE_LOGIN);
                  }}
                >
                  Sign In?
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
