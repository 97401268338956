import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { createApi } from "unsplash-js";
import { Basic } from "unsplash-js/dist/methods/photos/types";
import { UNSPLASH_TOKEN } from "@utils/constant";
import UnsplashTab from "./UnsplashTab";
import LocalTab from "./LocalTab";
import PexelsTab from "./PexelsTab";
import { Photo } from "pexels";
import LinkTab from "./LinkTab";

enum Tab {
  Local = 0,
  Link = 1,
  Unsplash = 2,
  Pexels = 3,
}

const unsplash = createApi({
  accessKey: UNSPLASH_TOKEN,
});

function ImagePickerModal({
  open = false,
  onCancel = () => {},
  onSubmit = () => {},
}: {
  open?: boolean;
  onCancel?: () => void;
  onSubmit?: (image: string | File | undefined) => void;
}) {
  let beforeOnSubmitCallback = async () => {};

  const cancelButtonRef = useRef(null);
  const [openTab, setOpenTab] = useState(0);
  const [selectedImage, setSelectedImage] = useState<
    string | File | undefined
  >();

  const onLocalImageSelect = (file: File) => {
    setSelectedImage(file);
  };

  const onLinkImageSelect = (image: string) => {
    setSelectedImage(image);
  };

  const onUnsplashImageSelect = async (image: Basic) => {
    beforeOnSubmitCallback = async () => {
      await unsplash.photos.trackDownload({
        downloadLocation: image.links.download_location,
      });
    };
    setSelectedImage(image.urls.regular);
  };

  const onPexelsImageSelect = (image: Photo) => {
    setSelectedImage(image.src.large);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onCancel}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block transform overflow-hidden rounded-lg bg-white text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl sm:align-middle">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div>
                  <div className="text-center sm:text-left">
                    <Dialog.Title
                      as="h1"
                      className="mb-4 text-2xl font-bold leading-6 text-gray-900"
                    >
                      Upload Image
                    </Dialog.Title>
                    <div>
                      <ul
                        className="mb-0 flex list-none flex-row flex-wrap pt-3 pb-4"
                        role="tablist"
                      >
                        <li className="-mb-px mr-2 text-center last:mr-0">
                          <a
                            className={
                              "block border-b py-3 text-sm font-bold uppercase leading-normal " +
                              (openTab === Tab.Local
                                ? "border-b-blue-500 text-blue-500"
                                : "border-b-white text-gray-500")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(Tab.Local);
                            }}
                            data-toggle="tab"
                            href={`#link${Tab.Local}`}
                            role="tablist"
                          >
                            <i className="fas fa-space-shuttle mr-1 text-base"></i>{" "}
                            From your desktop
                          </a>
                        </li>
                        <li className="-mb-px mr-2 text-center last:mr-0">
                          <a
                            className={
                              "block border-b py-3 text-sm font-bold uppercase leading-normal " +
                              (openTab === Tab.Link
                                ? "border-b-blue-500 text-blue-500"
                                : "border-b-white text-gray-500")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(Tab.Link);
                            }}
                            data-toggle="tab"
                            href={`#link${Tab.Link}`}
                            role="tablist"
                          >
                            <i className="fas fa-space-shuttle mr-1 text-base"></i>{" "}
                            From URL
                          </a>
                        </li>
                        <li className="-mb-px mr-2 text-center last:mr-0">
                          <a
                            className={
                              "block border-b py-3 text-sm font-bold uppercase leading-normal " +
                              (openTab === Tab.Unsplash
                                ? "border-b-blue-500 text-blue-500"
                                : "border-b-white text-gray-500")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(Tab.Unsplash);
                            }}
                            data-toggle="tab"
                            href={`#link${Tab.Unsplash}`}
                            role="tablist"
                          >
                            <i className="fas fa-cog mr-1 text-base"></i> From
                            Unsplash
                          </a>
                        </li>
                        <li className="-mb-px mr-2 text-center last:mr-0">
                          <a
                            className={
                              "block border-b py-3 text-sm font-bold uppercase leading-normal " +
                              (openTab === Tab.Pexels
                                ? "border-b-blue-500 text-blue-500"
                                : "border-b-white text-gray-500")
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              setOpenTab(Tab.Pexels);
                            }}
                            data-toggle="tab"
                            href={`#link${Tab.Pexels}`}
                            role="tablist"
                          >
                            <i className="fas fa-cog mr-1 text-base"></i> From
                            Pexels
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div>
                      {openTab === Tab.Local && (
                        <LocalTab onSelect={onLocalImageSelect} />
                      )}
                      {openTab === Tab.Link && (
                        <LinkTab onSelect={onLinkImageSelect} />
                      )}
                      {openTab === Tab.Unsplash && (
                        <UnsplashTab onSelect={onUnsplashImageSelect} />
                      )}
                      {openTab === Tab.Pexels && (
                        <PexelsTab onSelect={onPexelsImageSelect} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded border border-transparent bg-indigo-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={async () => {
                    await beforeOnSubmitCallback();
                    onSubmit(selectedImage);
                  }}
                >
                  Confirm
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    onCancel();
                  }}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ImagePickerModal;
