import {
  TrashIcon,
  DocumentDuplicateIcon,
  LockOpenIcon,
  LockClosedIcon,
} from "@heroicons/react/20/solid";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import { Template } from "../../type";
import Snapshot from "@components/shared/Snapshot";

type Props = {
  template: Template;
  onOpen: (templateId: string) => void;
  onRemove: (templateId: string) => void;
  onDuplicate: (templateId: string) => void;
};

function TemplateItem({ template, onOpen, onRemove, onDuplicate }: Props) {
  const date = new Date(template.updated_at);
  const ago = formatDistanceToNow(date, {
    addSuffix: true,
  });

  return (
    <div>
      <div className="group relative flex flex-col rounded bg-white p-4 shadow-md">
        <div className="absolute top-2 right-2 z-50 hidden flex-col space-y-1 group-hover:flex">
          <button
            title="Remove this template"
            className="cursor-pointer rounded bg-gray-50 p-2 shadow-md"
            onClick={(event) => {
              event.stopPropagation();
              onRemove(template.id);
            }}
          >
            <TrashIcon className="h-5 w-5 text-gray-500" />
          </button>

          <button
            title="Duplicate this template"
            className="cursor-pointer rounded bg-gray-50 p-2 shadow-md"
            onClick={(event) => {
              event.stopPropagation();
              onDuplicate(template.id);
            }}
          >
            <DocumentDuplicateIcon className="h-5 w-5 text-gray-500" />
          </button>
        </div>
        <div
          className="mb-2 flex h-56 cursor-pointer flex-col items-center justify-center rounded bg-gray-200 p-5 hover:bg-gray-300"
          onClick={() => {
            onOpen(template.id);
          }}
        >
          <Snapshot template={template} />
        </div>
        <div className="flex items-center justify-between py-2">
          <div className="mr-2 flex min-w-0 flex-1 items-center justify-start space-x-2 overflow-hidden text-ellipsis">
            <div className="text-blue-400">
              {template.public ? (
                <LockOpenIcon className="h-4 w-4" />
              ) : (
                <LockClosedIcon className="h-4 w-4" />
              )}
            </div>
            <p className="overflow-hidden text-ellipsis whitespace-nowrap text-sm font-bold">
              {template.title}
            </p>
          </div>
          <div className="flex items-center justify-center space-x-2">
            <p className="text-sm">{ago}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TemplateItem;
