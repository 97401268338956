import { IconName } from "../../shared/Icon";

export interface TemplateItemInfo {
  label: string;
  width: number;
  height: number;
  data: string;
  icon: IconName;
}

export type TemplateItemCategories = keyof typeof Templates;

const Templates: {
  common: TemplateItemInfo[];
  mobile: TemplateItemInfo[];
  sns: TemplateItemInfo[];
} = {
  common: [
    {
      label: "Horizontal",
      width: 1200,
      height: 630,
      data: "",
      icon: "File",
    },
    {
      label: "Vertical",
      width: 630,
      height: 1200,
      data: "",
      icon: "File",
    },
    {
      label: "Square",
      width: 600,
      height: 600,
      data: "",
      icon: "Square",
    },
  ],
  mobile: [
    {
      label: "iPhone X",
      width: 1125,
      height: 2436,
      data: "",
      icon: "Phone",
    },
    {
      label: "iPhone 8/7/6 Plus",
      width: 1242,
      height: 2208,
      data: "",
      icon: "Phone",
    },
    {
      label: "iPhone 8/7/6",
      width: 750,
      height: 1334,
      data: "",
      icon: "Phone",
    },
  ],
  sns: [
    {
      label: "Twitter Banner",
      width: 1500,
      height: 500,
      data: "",
      icon: "Twitter",
    },
    {
      label: "Twitter Image",
      width: 1200,
      height: 675,
      data: "",
      icon: "Twitter",
    },
    {
      label: "Facebook Cover",
      width: 1640,
      height: 924,
      data: "",
      icon: "Facebook",
    },
    {
      label: "Facebook Post",
      width: 940,
      height: 788,
      data: "",
      icon: "Facebook",
    },
    {
      label: "LinkedIn Cover",
      width: 1584,
      height: 396,
      data: "",
      icon: "Linkedin",
    },
    {
      label: "Instagram Story",
      width: 1080,
      height: 1920,
      data: "",
      icon: "Instagram",
    },
    {
      label: "Instagram Post",
      width: 1080,
      height: 1080,
      data: "",
      icon: "Instagram",
    },
    {
      label: "YouTube Banner",
      width: 2560,
      height: 1440,
      data: "",
      icon: "Youtube",
    },
    {
      label: "YouTube Thumbnail",
      width: 1280,
      height: 720,
      data: "",
      icon: "Youtube",
    },
    {
      label: "Standard Pin",
      width: 1000,
      height: 1500,
      data: "",
      icon: "Pinterest",
    },
    {
      label: "Story Pin",
      width: 1080,
      height: 1920,
      data: "",
      icon: "Pinterest",
    },
  ],
};

export default Templates;
