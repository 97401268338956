import { useNavigate } from "react-router-dom";
import {
  ROUTE_RENDERINGS,
  ROUTE_DASHBOARD,
  RouteType,
} from "@constants/routes";
import {
  PaintBrushIcon,
  PhotoIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";

interface Overview {
  totalRenderingCount: string;
  totalTemplateCount: string;
  monthlyRenderingCount: string;
  templateLimit: number;
  monthlyRenderingLimit: number;
}

type keys = keyof Overview;

const stats: {
  key: keys;
  name: string;
  icon: React.FC<React.ComponentProps<"svg">>;
  navigateTo?: RouteType;
}[] = [
  {
    key: "totalTemplateCount",
    name: "Templates",
    icon: PaintBrushIcon,
    navigateTo: ROUTE_DASHBOARD,
  },
  {
    key: "totalRenderingCount",
    name: "Total Renderings",
    icon: PhotoIcon,
    navigateTo: ROUTE_RENDERINGS,
  },
  {
    key: "monthlyRenderingCount",
    name: "Monthly Renderings",
    icon: CalendarDaysIcon,
  },
];

export default function Stats({
  overview = {
    totalRenderingCount: "Loading ...",
    totalTemplateCount: "Loading ...",
    monthlyRenderingCount: "Loading ...",
    monthlyRenderingLimit: 0,
    templateLimit: 0,
  },
}: {
  overview: Overview;
}) {
  const navigate = useNavigate();

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {stats.map((item, index) => (
          <div
            key={index}
            className={`relative overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:px-6 sm:pt-6 ${
              item.navigateTo ? "cursor-pointer" : ""
            }`}
            onClick={() => {
              if (item.navigateTo) {
                navigate(item.navigateTo);
              }
            }}
          >
            <dt>
              <div className="absolute rounded-md bg-blue-50 p-3">
                <item.icon
                  className="h-6 w-6 text-gray-500"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">
                {item.name}
              </p>
            </dt>
            <dd className="ml-16 flex items-baseline">
              <p className="text-2xl font-semibold text-gray-900">
                {overview[item.key]}
                {item.key === "totalTemplateCount" && (
                  <span className="text-sm font-medium text-gray-500">
                    {" "}
                    / {overview.templateLimit}
                  </span>
                )}
                {item.key === "monthlyRenderingCount" && (
                  <span className="text-sm font-medium text-gray-500">
                    {" "}
                    / {overview.monthlyRenderingLimit}
                  </span>
                )}
              </p>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}
