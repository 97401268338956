export const ROUTE_LOGIN = "/login";

export const ROUTE_LOGOUT = "/logout";

export const ROUTE_REGISTER = "/register";

export const ROUTE_PLAYGROUND = "/playground";

export const ROUTE_PROFILE = "/profile";

export const ROUTE_RENDERINGS = "/renderings";

export const ROUTE_DASHBOARD = "/";

export const ROUTE_PREVIEW = "/preview";

export const ROUTE_EDIT = "/edit";

export const ROUTE_PEEK = "/peek";

export const ROUTE_UPGRADE = "/upgrade";

export const ROUTE_AUTH_VERIFY_EMAIL = "/auth/verify_email";

export type RouteType =
  | typeof ROUTE_LOGIN
  | typeof ROUTE_LOGOUT
  | typeof ROUTE_REGISTER
  | typeof ROUTE_PLAYGROUND
  | typeof ROUTE_PROFILE
  | typeof ROUTE_RENDERINGS
  | typeof ROUTE_DASHBOARD
  | typeof ROUTE_PREVIEW
  | typeof ROUTE_EDIT
  | typeof ROUTE_PEEK
  | typeof ROUTE_UPGRADE
  | typeof ROUTE_AUTH_VERIFY_EMAIL;
