import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { authInstance, firebaseAuth } from "@utils/firebase";
import { ROUTE_LOGIN } from "@constants/routes";

function Logout() {
  const navigate = useNavigate();

  useEffect(() => {
    firebaseAuth.signOut(authInstance).finally(() => {
      navigate(ROUTE_LOGIN, { replace: true });
    });
  }, [navigate]);

  return <></>;
}

export default Logout;
