import { fabric } from "@utils/fabric";
import { TypeUnderline } from "react-bootstrap-icons";
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";

function FontUnderline() {
  const { canvas, selectedObject } = useContext(AppContext);
  const [underline, setUnderline] = useState(
    (selectedObject as fabric.Textbox)?.underline
  );

  // triggered by clicking layers
  useEffect(() => {
    setUnderline((selectedObject as fabric.Textbox)?.underline);
  }, [selectedObject]);

  function onClick() {
    setUnderline(!underline);
    (selectedObject as fabric.Textbox)?.set({ underline: !underline });
    canvas?.renderAll();
  }

  return (
    <div>
      <button
        className={`rounded border border-gray-500 p-2 ${
          underline ? "bg-blue-400 text-white" : ""
        }`}
        onClick={onClick}
      >
        <TypeUnderline size={16} />
      </button>
    </div>
  );
}

export default FontUnderline;
