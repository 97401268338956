import React, { useState } from "react";
import { fabric } from "@utils/fabric";
import { AppContext } from "../context/AppContext";
import CoreHandler from "../handlers/CoreHandler";
import { Template, User } from "../type";

export const AppProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [coreHandler, setCoreHandler] = useState<CoreHandler | null>(null);
  const [isAppInitialized, setIsAppInitialized] = useState<boolean>(false);
  const [isLayerOpened, setIsLayerOpened] = useState<boolean>(true);
  const [isWidthHeightProportionalScaled, setIsWidthHeightProportionalScaled] =
    useState(true);
  const [selectedObject, setSelectedObject] = useState<fabric.Object | null>(
    null
  );

  const value = {
    canvas,
    setCanvas,
    selectedObject,
    setSelectedObject,
    coreHandler,
    setCoreHandler,
    templates,
    setTemplates,
    user,
    setUser,
    isLayerOpened,
    setIsLayerOpened,
    isWidthHeightProportionalScaled,
    setIsWidthHeightProportionalScaled,
    isAppInitialized,
    setIsAppInitialized,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
