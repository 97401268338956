import { INTERNAL_SERVER_URL } from "@constants/api";
import { authInstance } from "@utils/firebase";

async function listRenderings(page = 1, limit = 20) {
  const idToken = await authInstance.currentUser?.getIdToken();

  const response = await fetch(
    `${INTERNAL_SERVER_URL}/rendering/list?page=${page}&limit=${limit}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
      },
    }
  );
  const result = await response.json();

  if (response.ok) {
    return result;
  } else {
    throw new Error(result.error);
  }
}

export { listRenderings };
