import Header from "@components/Header";
import Loader from "@components/shared/Loader";
import { listRenderings } from "@apis/rendering";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/AppContext";
import ReactPaginate from "react-paginate";
import { Rendering } from "@base/type";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";
import { ThreeDots } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import CodeSnippetModal from "@components/Modals/CodeSnippetModal/CodeSnippetModal";
import NoRenderings from "@components/Renderings/NoRenderings";
import { ROUTE_EDIT } from "@constants/routes";

function RenderingPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [renderings, setRenderings] = useState<Rendering[]>([]);
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false);
  const [selectedObject, setSelectedObject] = useState({});
  const { templates } = useContext(AppContext);

  const areChangesValid = (changes) => {
    return changes && changes.length > 0;
  };

  const areOptionsValid = (options) => {
    // TODO - change API later, _id is always present
    return options && Object.keys(options).length > 1;
  };

  const loadPage = async (pageIndex = 1) => {
    try {
      const { data } = await listRenderings(pageIndex);
      setPage(data.currentPage);
      setRenderings(data.renderings);
      setTotalPages(data.totalPages);
    } catch (error) {
      setRenderings([]);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onPageChange = async (event) => {
    const newPage = event.selected + 1;
    if (page !== newPage) {
      loadPage(newPage);
    }
  };

  const onOptionsOrChangesClick = (object) => {
    setSelectedObject(object);
    setIsConfirmModalShown(true);
  };

  const getTemplateNameFromRendering = (rendering: Rendering): string => {
    return (
      templates.find((template) => template.id === rendering.template_id)
        ?.title ?? "Deleted Template"
    );
  };

  async function onClickToRender(rendering: Rendering) {
    if (isDownloading || rendering.status !== "success") {
      return;
    }

    setIsDownloading(true);

    const toastId = toast.loading("Downloading image...");

    await fetch(rendering.permanent_url)
      .then((res) => res.blob())
      .then((blob) => {
        saveAs(blob, "image.png");
      });

    toast.update(toastId, {
      render: "Image downloaded!",
      type: "success",
      isLoading: false,
      autoClose: 2000,
    });

    setIsDownloading(false);
  }

  useEffect(() => {
    (async () => {
      await loadPage(1);
    })();
  }, []);

  return (
    <div className="h-full">
      <Header />
      <div className="relative h-[calc(100%-73px)]">
        {isLoading && (
          <div className="h-full w-full">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <div className="flex items-center justify-center">
                <div className="mr-2">
                  <Loader />
                </div>
                <div>Loading Renderings ...</div>
              </div>
            </div>
          </div>
        )}
        {!isLoading && renderings.length === 0 && (
          <div className="h-full w-full">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
              <NoRenderings />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Template Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Changes
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Options
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Created At
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Download</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                      {renderings.map((rendering) => (
                        <tr key={rendering.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            <Link to={`${ROUTE_EDIT}/${rendering.template_id}`}>
                              {getTemplateNameFromRendering(rendering)}
                            </Link>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {rendering.status === "success" && (
                              <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                Success
                              </span>
                            )}
                            {rendering.status === "processing" && (
                              <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
                                Processing
                              </span>
                            )}
                            {rendering.status === "failed" && (
                              <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                Failed
                              </span>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {areChangesValid(rendering.changes) ? (
                              <ThreeDots
                                size={16}
                                className="cursor-pointer rounded border"
                                onClick={() => {
                                  onOptionsOrChangesClick(rendering.changes);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {areOptionsValid(rendering.options) ? (
                              <ThreeDots
                                size={16}
                                className="cursor-pointer rounded border"
                                onClick={() => {
                                  onOptionsOrChangesClick(rendering.options);
                                }}
                              />
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {new Date(rendering.created_at).toLocaleString()}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <button
                              className="focus:shadow-outline rounded bg-indigo-500 py-2 px-4 font-bold text-white hover:bg-indigo-600 focus:outline-none disabled:cursor-not-allowed disabled:opacity-50"
                              type="button"
                              onClick={() => {
                                onClickToRender(rendering);
                              }}
                              disabled={
                                isDownloading || rendering.status !== "success"
                              }
                            >
                              <span className="mr-1">Download</span>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <ReactPaginate
                onPageChange={onPageChange}
                pageCount={totalPages}
                containerClassName="flex justify-end items-center pt-4 pb-8"
                pageClassName="inline-flex items-center px-4 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0"
                breakClassName="inline-flex items-center px-4 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0"
                activeClassName="bg-indigo-600 text-white font-bold hover:!bg-indigo-600 !ring-indigo-600"
                previousClassName="inline-flex items-center px-4 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 rounded-l-md"
                nextClassName="inline-flex items-center px-4 py-2 text-sm text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-100 focus:z-20 focus:outline-offset-0 rounded-r-md"
                nextLabel="Next"
                previousLabel="Previous"
              />
            </div>
          </div>
        )}
      </div>
      <CodeSnippetModal
        code={JSON.stringify(selectedObject, null, 2) || "{}"}
        lang="json"
        open={isConfirmModalShown}
        onCancel={() => {
          setIsConfirmModalShown(false);
        }}
      />
    </div>
  );
}

export default RenderingPage;
