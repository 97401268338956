import confetti from "canvas-confetti";

function fire(particleRatio: number, opts: Record<string, unknown>) {
  const count = 200;

  // NOTE: paddle uses 99999 for checkout iframe, so I added one more 9 here to make it higher
  const defaults = {
    origin: { y: 0.7 },
    zIndex: 999999,
  };

  confetti(
    Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio),
    })
  );
}

function fireConfetti() {
  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
}

export { fireConfetti };
