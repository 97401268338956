import {
  ObjectChange,
  TextChange as TextChangeType,
  ImageChange as ImageChangeType,
} from "@eragonj/copicake-fabric/dist/types/utils";
import ImageChange from "./ImageChange";
import TextChange from "./TextChange";

interface IProps {
  change: ObjectChange;
  onValueUpdated: (change: ObjectChange) => void;
  onRemove: () => void;
}

function Change({ change, onValueUpdated, onRemove }: IProps) {
  const type = "text" in change ? "Text" : "Image";
  const content =
    type === "Text" ? (
      <TextChange
        change={change as TextChangeType}
        onValueUpdated={onValueUpdated}
      />
    ) : (
      <ImageChange
        change={change as ImageChangeType}
        onValueUpdated={onValueUpdated}
      />
    );

  return (
    <div className="relative mb-4">
      <label className="text-md mb-2 block font-bold text-gray-700">
        <span>{type} Change</span>
      </label>
      <button
        className="absolute right-0 top-1 text-red-500"
        onClick={onRemove}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <div>{content}</div>
    </div>
  );
}

export default Change;
