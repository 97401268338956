import { useState } from "react";
import { Image } from "react-bootstrap-icons";

function LocalTab({ onSelect = () => {} }: { onSelect: (file: File) => void }) {
  const [dragActive, setDragActive] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const onDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const onDrop = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files && e.dataTransfer.files[0];
    handleFile(file);
  };

  const onFileChange = (e: React.ChangeEvent) => {
    const input = e.target as HTMLInputElement;
    const file = input.files && input.files[0];
    handleFile(file);
  };

  const handleFile = (file: File | null) => {
    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = (event) => {
        const dataURL = event.target?.result as string;
        setSelectedImage(dataURL);
        onSelect(file);
      };

      fileReader.readAsDataURL(file);
    }
  };
  return (
    <form
      onDragEnter={onDrag}
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className="relative overflow-hidden"
    >
      <input
        id="input-file-upload"
        type="file"
        className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
        onChange={onFileChange}
      />
      <label
        htmlFor="input-file-upload"
        className={`flex rounded border-2 border-dashed ${
          dragActive ? "border-blue-500" : ""
        }`}
      >
        {selectedImage && (
          <div className="flex w-full items-center justify-center p-6">
            <img src={selectedImage} alt="" className="object-fit h-60"></img>
          </div>
        )}
        {!selectedImage && (
          <button className="flex w-full flex-col items-center justify-center space-y-4 p-28">
            <Image size={30} />
            <div className="text-sm">
              {!dragActive && (
                <div>
                  <span className="font-bold">Choose a file</span> or drag it
                  here
                </div>
              )}
              {dragActive && (
                <span className="font-bold">Yes, just drop it!</span>
              )}
            </div>
          </button>
        )}
      </label>
      {dragActive && (
        <div
          className="absolute left-0 top-0 right-0 bottom-0 h-full w-full"
          onDragEnter={onDrag}
          onDragLeave={onDrag}
          onDragOver={onDrag}
          onDrop={onDrop}
        ></div>
      )}
    </form>
  );
}

export default LocalTab;
