import { Routes, Route, Navigate } from "react-router-dom";
import { extendFabricProperties } from "./lib/fabric/extendFabricProperties";
import Login from "@pages/Login";
import Logout from "@pages/Logout";
import Editor from "@pages/Editor";
import Profile from "@pages/Profile";
import Rendering from "@pages/Rendering";
import Register from "@pages/Register";
import Dashboard from "@pages/Dashboard";
import Playground from "@pages/Playground";
import Upgrade from "@pages/Upgrade";
import Peek from "@pages/Peek";
import Preview from "@pages/Preview";
import ImageSrc from "@pages/experiment/ImageSrc";
import RequireAuth from "@components/RequireAuth";
import VerifyEmail from "@pages/auth/VerifyEmail";
import { isLocalhost } from "@utils/env";
import {
  ROUTE_AUTH_VERIFY_EMAIL,
  ROUTE_DASHBOARD,
  ROUTE_EDIT,
  ROUTE_LOGIN,
  ROUTE_LOGOUT,
  ROUTE_PEEK,
  ROUTE_PLAYGROUND,
  ROUTE_PREVIEW,
  ROUTE_PROFILE,
  ROUTE_REGISTER,
  ROUTE_RENDERINGS,
  ROUTE_UPGRADE,
} from "@constants/routes";
import PaidUserOnly from "@components/PaidUserOnly";

extendFabricProperties();

function App() {
  return (
    <Routes>
      {/* Testing Routes */}
      {isLocalhost && (
        <Route path="/experiment/image_src" element={<ImageSrc />} />
      )}
      <Route
        path={ROUTE_RENDERINGS}
        element={
          <RequireAuth>
            <PaidUserOnly>
              <Rendering />
            </PaidUserOnly>
          </RequireAuth>
        }
      />
      <Route path={ROUTE_LOGIN} element={<Login />} />
      <Route path={ROUTE_LOGOUT} element={<Logout />} />
      <Route path={ROUTE_REGISTER} element={<Register />} />
      <Route path={ROUTE_PLAYGROUND} element={<Playground />} />
      <Route
        path={ROUTE_PROFILE}
        element={
          <RequireAuth>
            <Profile />
          </RequireAuth>
        }
      />
      <Route path={`${ROUTE_PEEK}/:templateId`} element={<Peek />} />
      <Route
        path={`${ROUTE_EDIT}/:templateId`}
        element={
          <RequireAuth>
            <Editor />
          </RequireAuth>
        }
      />
      <Route
        path={`${ROUTE_PREVIEW}/:templateId`}
        element={
          <RequireAuth>
            <Preview />
          </RequireAuth>
        }
      />
      <Route
        path={ROUTE_DASHBOARD}
        element={
          <RequireAuth>
            <Dashboard />
          </RequireAuth>
        }
      />
      <Route path={ROUTE_AUTH_VERIFY_EMAIL} element={<VerifyEmail />} />
      <Route path={ROUTE_UPGRADE} element={<Upgrade />} />
      <Route path="*" element={<Navigate to={ROUTE_LOGIN} />} />
    </Routes>
  );
}

export default App;
