import { Photo } from "pexels";

function PexelsImage({
  data,
  selected = false,
  onImageClick = (image: Photo) => {},
}: {
  data: Photo;
  selected: boolean;
  onImageClick: (image: Photo) => void;
}) {
  const backLink = `${data.url}`;

  return (
    <div
      className={`relative h-48 w-1/2 overflow-hidden rounded-sm border-4 ${
        selected ? "border-blue-500" : "border-white"
      }`}
    >
      <img
        src={data.src.medium}
        alt={data.alt || "pexels image"}
        className="w-full cursor-pointer object-cover"
        onClick={() => {
          onImageClick(data);
        }}
      ></img>
      <a
        className="absolute bottom-0 left-0 w-full bg-black bg-opacity-50 p-2 text-xs text-white"
        href={backLink}
        target="_blank"
        rel="noreferrer"
      >
        Photo by <span className="font-bold">{data.photographer} </span>
        on <span className="font-bold">Pexels</span>
      </a>
    </div>
  );
}

export default PexelsImage;
